import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const MenuItem = ({ icon, isDisabled, isSelected, onClick, children }: MenuItemProps) => {
  return (
    <ItemWrapper $hasIcon={Boolean(icon)} $isDisabled={isDisabled} $isSelected={isSelected} onClick={onClick}>
      <>
        {icon && <ItemIcon>{icon}</ItemIcon>}
        {children}
      </>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.li<{ $hasIcon?: boolean; $isDisabled?: boolean; $isSelected?: boolean }>`
  display: block;
  padding: ${({ $hasIcon }) => ($hasIcon ? '14px 16px 14px 16px' : '14px 16px')};
  transition: background-color 0.2s ease-out, opacity 0.2s ease-out;
  cursor: pointer;
  position: relative;

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
      opacity: 0.3;
      pointer-events: none;
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
      background-color: ${Colors.PrimaryTransparent};
    `}

  &:hover {
    background-color: ${Colors.ContrastTransparent};
  }

  > a {
    padding: ${({ $hasIcon }) => ($hasIcon ? '14px 16px 14px 34px' : '14px 16px')};
    text-decoration: none;
    color: ${Colors.Black};
    display: flex;
  }
`;

const ItemIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  > svg {
    width: 1.5em;
    height: 1.5em;
  }
`;

export interface MenuItemProps {
  icon?: JSX.Element;
  children?: string | JSX.Element;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: () => any;
}
