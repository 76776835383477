import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation } from '../../generated/types';

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const useLogout = (options?: MutationHookOptions<Pick<Mutation, "logout">, void>) =>
  useMutation<Pick<Mutation, "logout">, void>(LOGOUT, options);
