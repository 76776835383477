// https://dev.to/thesanjeevsharma/adding-feature-flags-to-your-react-codebase-22a8
import React, { createContext, useContext } from 'react';

export const FeatureFlags = createContext<FeatureFlagsContext>({
  isAIEnabled: false,
});

export const useFeatureFlags = () => useContext(FeatureFlags);

export const FeatureFlagsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const env = import.meta.env.VITE_APP_ENV || 'prod';
  return (
    <FeatureFlags.Provider
      value={{
        isAIEnabled: ['local', 'dev'].includes(env),
      }}
    >
      {children}
    </FeatureFlags.Provider>
  );
};

type FeatureFlagsContext = {
  isAIEnabled: boolean;
};
