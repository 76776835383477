import { Navigate } from 'react-router';

import { Urls } from './urls';
import { useRouter } from '../hooks/useRouter';
import { useAuthContext } from '../contexts/Auth0Context';

export function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { isLoading, isAuthenticated } = useAuthContext();
  const { pathname } = useRouter();

  if (isLoading) {
    return <></>;
  } else if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to={`/${Urls.Login}`} state={{ from: pathname }} replace />;
  }
}
