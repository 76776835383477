import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationSignInConfirmArgs } from '../../generated/types';

export const SIGNIN_CONFIRM = gql`
  mutation SignInConfirm($accessToken: String!, $state: String!) {
    signInConfirm(accessToken: $accessToken, state: $state) {
      uuid
      sub
      nickname
      name
      email
      email_verified
      picture
      state
      jwt
    }
  }
`;

export const useSignInConfirm = (
  options?: MutationHookOptions<Pick<Mutation, 'signInConfirm'>, MutationSignInConfirmArgs>
) => useMutation<Pick<Mutation, 'signInConfirm'>, MutationSignInConfirmArgs>(SIGNIN_CONFIRM, options);
