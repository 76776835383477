import { QuestionAnswer } from '@styled-icons/remix-line';
import { QuestionAnswer as QuestionAnswerFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function FaqIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <QuestionAnswer />
    </Icon>
  );
}

export function FaqFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <QuestionAnswerFill />
    </Icon>
  );
}
