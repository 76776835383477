import { useState } from 'react';

// // Usage
// function App() {
//   // Similar to useState but first arg is key to the value in local storage.
//   const [name, setName] = useLocalStorage('name', 'Bob');
//
//   return (
//     <div>
//       <input type="text" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)} />
//     </div>
//   );
// }

// Hook
export function useLocalStorage(key: string, initialValue: string): any[] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      try {
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        return item ? item : initialValue;
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const writeStorage = <T extends unknown>(valueToStore: T) => {
    try {
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(
        key,
        typeof valueToStore === 'object' ? JSON.stringify(valueToStore) : `${valueToStore}`
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  const deleteFromStorage = () => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, writeStorage, deleteFromStorage];
}
