// https://codesandbox.io/s/framer-motion-5-3-scroll-triggered-animations-2hbg5?from-embed
import { motion, AnimatePresence, useInView } from 'framer-motion';
import styled, { css } from 'styled-components';

import { BodyStyle2 } from '../../../styles/fonts';
import { useEffect, useRef } from 'react';

export enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const Placeholder = ({
  title,
  description,
  lottie,
  icon,
  iconAlt,
  iconSize = Size.SMALL,
  isAnimated = 'none',
}: PlaceholderProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const style =
    isAnimated !== 'none'
      ? {
          opacity: isInView ? 1 : 0.5,
          transform: isInView
            ? 'translateX(0px)'
            : isAnimated === 'right'
            ? 'translateX(-200px)'
            : 'translateX(+200px)',
          transition: 'all 1.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }
      : {};

  if (lottie) {
    return (
      <WrapperGlobal>
        <WrapperLottie style={style} ref={ref}>
          {lottie}
          {title && <Title>{title}</Title>}
          {description && <div>{description}</div>}
        </WrapperLottie>
      </WrapperGlobal>
    );
  }

  if (icon) {
    return (
      <WrapperGlobal>
        <Wrapper $iconSize={iconSize} style={style} ref={ref}>
          <img alt={iconAlt} src={icon} />
          {title && <Title>{title}</Title>}
          {description && <div>{description}</div>}
        </Wrapper>
      </WrapperGlobal>
    );
  }

  return null;
};

const WrapperGlobal = styled.div`
  overflow: hidden;
`;

const WrapperLottie = styled.div`
  padding: 0;
  text-align: center;
  margin: 0 auto;
  max-width: 500px;

  h4 {
    margin: 0;
  }
  h4 + div {
    margin-top: 20px;
  }
  /* > div {
    display: inline-block;
    max-width: 300px;
    ${BodyStyle2}
    p {
      ${BodyStyle2}
    }
    span,
    a {
      text-decoration: underline;
      cursor: pointer;
    }
  } */
`;

const Wrapper = styled.div<{
  $iconSize: Size;
}>`
  text-align: center;
  padding-block: 0px 32px;
  padding-inline: 16px;

  img {
    margin-top: 60px;
    margin-bottom: 20px;
    max-width: 100px;
    margin-inline: auto;
    width: 100%;
    ${({ $iconSize }) => {
      switch ($iconSize) {
        case Size.MEDIUM:
          return css`
            max-width: 300px;
          `;
        case Size.LARGE:
          return css`
            max-width: 500px;
          `;
      }
    }}
  }
  h4 {
    margin-bottom: 16px;
  }
`;

const Title = styled.h4`
  padding: 16px 0 0 0;
`;

interface PlaceholderProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  icon?: string;
  iconAlt?: string;
  iconSize?: Size;
  lottie?: any;
  isAnimated?: 'left' | 'right' | 'none';
}
