import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { EntityType, SortDirection } from '../../generated/types';

import voidImage from '../../assets/vectors/man-character14.svg';

import { Urls } from '../urls';
import { Colors } from '../../styles/colors';
import { formatDate } from '../../utils/date';

import mailBox from '../../assets/vectors/mail_box2.svg';

import { useLazyGetNotifications } from '../../graph/queries/getNotifications';

import { Paper } from '../../components/atoms/Paper';
import { H4 } from '../../components/atoms/Typography';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import { ViewIcon } from '../../components/atoms/Icons';
import { formatUrl } from '../../utils/url';
import { SmallLoader } from '../../components/atoms/Loader';
import { Placeholder } from '../../components/atoms/Placeholder';
import { Size } from '../../components/atoms/Placeholder/Placeholder';

export const DashboardNotifications = ({ userUuid, isDrawer = false }: DashboardJobsProps): React.ReactNode => {
  const [getNotifications, { data, loading }] = useLazyGetNotifications({
    variables: {
      where: {
        userUuid,
        isDeleted: false,
      },
      sort: { direction: SortDirection.Desc, field: 'dateTimeIndex' },
      limit: 10,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    async function fetchNotifications() {
      await getNotifications();
    }
    fetchNotifications();
  }, [userUuid]);

  const notifications = data?.notifications || [];

  let children = isDrawer ? (
    <Placeholder icon={mailBox} iconAlt="404" iconSize={Size.SMALL} title="No new notifications." />
  ) : (
    <Placeholder icon={voidImage} iconAlt="404" iconSize={Size.SMALL} title="" />
  );
  if (notifications.length > 0) {
    children = (
      <Wrapper>
        {notifications.map((notification) => {
          const hash = notification.data?.hash ? `#${notification.data?.hash}` : '';
          return (
            <ItemWrapper key={notification.uuid}>
              <DateTime>
                {formatDate({
                  date: notification.createdAt,
                  format: 'shortdatetime',
                })}
                :
              </DateTime>
              <Description dangerouslySetInnerHTML={{ __html: notification.description }}></Description>
              {/* <pre>{JSON.stringify(notification, null, 2)}</pre> */}
              {notification?.data?.entityType === EntityType.Board && (
                <More>
                  <Link
                    to={`/${Urls.Boards}/${formatUrl(Urls.BoardView, {
                      userUuid: notification?.data?.userUuid,
                      boardUuid: notification?.data?.boardUuid,
                    })}${hash}`}
                  >
                    <LineWrapper>
                      View Board
                      <ViewIcon size={0.8} />
                    </LineWrapper>
                  </Link>
                </More>
              )}

              {notification?.data?.entityType === EntityType.Job && (
                <More>
                  <Link
                    to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, {
                      userUuid: notification?.data?.userUuid,
                      boardUuid: notification?.data?.boardUuid,
                      jobUuid: notification?.data?.jobUuid,
                    })}${hash}`}
                  >
                    <LineWrapper>
                      View Job Application
                      <ViewIcon size={0.8} />
                    </LineWrapper>
                  </Link>
                </More>
              )}

              {notification?.data?.entityType === EntityType.Event && (
                <More>
                  <Link
                    to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, {
                      userUuid: notification?.data?.userUuid,
                      boardUuid: notification?.data?.boardUuid,
                      jobUuid: notification?.data?.jobUuid,
                    })}${hash}`}
                  >
                    <LineWrapper>
                      View Job Application
                      <ViewIcon size={0.8} />
                    </LineWrapper>
                  </Link>
                </More>
              )}
            </ItemWrapper>
          );
        })}
      </Wrapper>
    );
  }

  return (
    <ErrorBoundary message="\Dashboard DashboardNotifications">
      <Paper elevation={isDrawer ? 0 : 2}>
        <H4>Latest Notifications</H4>
        {loading ? <SmallLoader /> : children}
      </Paper>
    </ErrorBoundary>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const ItemWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.ContrastUltraLightest};
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.8rem;
`;

const DateTime = styled.span`
  font-size: 0.8rem;
  color: ${Colors.ContrastDarkest};
  line-height: 0.8rem;
  padding-right: 8px;
`;

const Description = styled.span`
  font-size: 0.8rem;
`;

const More = styled.span`
  font-weight: normal;
  display: flex;
  gap: 4px;

  a {
    color: ${Colors.Primary};
    text-decoration: none;
  }
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

type DashboardJobsProps = {
  userUuid: string;
  isDrawer?: boolean;
};
