import { GitRepositoryPrivate } from '@styled-icons/remix-line';
import { GitRepositoryPrivate as GitRepositoryPrivateFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function PadlockIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <GitRepositoryPrivate />
    </Icon>
  );
}

export function PadlockFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <GitRepositoryPrivateFill />
    </Icon>
  );
}
