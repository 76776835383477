import { css } from 'styled-components';

import { Colors } from './colors';
import { media } from './media';

export const Fonts = {
  fontFamily: '"Inter",helvetica,arial,sans-serif',
  alternativeFontFamily: 'Cormorant, sans-serif',
  niceFontFamily: 'Raleway, sans-serif',
};

export const FontWeights = {
  Thin: '100',
  ExtraLight: '200',
  Light: '300',
  Regular: '400',
  Bold: '700',
  ExtraBold: '800',
};

export const BaseFontStyles = css`
  font-family: ${Fonts.fontFamily};
  font-weight: ${FontWeights.Regular};
  color: ${Colors.Black};
`;

export const BodyStyle1 = css`
  font-size: var(--font-size-lg);
  line-height: var(--font-size-xl);
`;

export const BodyStyle2 = css`
  font-size: var(--font-size-md);
  line-height: var(--font-size-xl);
`;

export const BodyStyle3 = css`
  font-size: var(--font-size-sm);
  line-height: var(--font-size-md);
`;

export const BodyStyle4 = css`
  font-size: var(--font-size-xs);
  line-height: var(--font-size-sm);
`;

export const HeadingStyle1 = css`
  font-family: ${Fonts.niceFontFamily};
  font-weight: ${FontWeights.Light};
  font-size: var(--font-size-4xl);
  line-height: 1.5;
  letter-spacing: 1.88px;
  margin: 0 0 1em 0;
  position: relative;

  ${media.tablet`
    letter-spacing: 1.56px;
  `}

  ${media.phone`
    letter-spacing: 1.3px;
  `}

  ${media.small`
    letter-spacing: 1.2px;
  `}
`;

export const HeadingStyle2 = css`
  font-family: ${Fonts.niceFontFamily};
  font-weight: ${FontWeights.Regular};
  font-size: var(--font-size-3xl);
  line-height: 1.5;
  letter-spacing: 1.4px;
  margin: 0 0 1.5em 0;
  position: relative;

  ${media.tablet`
    letter-spacing: 1.11px;
  `}

  ${media.phone`
    letter-spacing: 1.04px;
  `}

  ${media.small`
    letter-spacing: 1px;
  `}
`;

export const HeadingStyle3 = css`
  font-family: ${Fonts.niceFontFamily};
  font-weight: ${FontWeights.Bold};
  font-size: var(--font-size-2xl);
  line-height: 1.6;
  margin: 0 0 1em 0;
`;

export const HeadingStyle4 = css`
  font-family: ${Fonts.niceFontFamily};
  font-weight: ${FontWeights.Bold};
  font-size: var(--font-size-xl);
  line-height: 1.5;
  margin: 0 0 1em 0;
`;
