// https://github.com/nexxtway/react-rainbow/tree/master/src/components/Avatar
import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { VisuallyHidden } from '../VisuallyHidden';

export const Avatar = ({ image, initials, size = 'medium', assistiveText = '', backgroundColor }: AvatarProps) => {
  let avatarContent = <StyledInitials>{initials}</StyledInitials>;

  if (image) {
    avatarContent = <StyledImage src={image} alt={assistiveText} />;
  }

  return (
    <StyledContainer size={size} backgroundColor={backgroundColor}>
      {avatarContent}
      <VisuallyHidden>{assistiveText}</VisuallyHidden>
    </StyledContainer>
  );
};

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  url?: string;
  image?: string;
  initials?: string;
  size?: 'large' | 'medium' | 'small' | 'x-small';
  assistiveText?: string;
  backgroundColor?: string;
}

const StyledInitials = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
`;

const StyledImage = styled.img`
  vertical-align: middle;
  border: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
`;

const StyledContainer = styled.span<{ size: string; backgroundColor: string }>`
  border-radius: 50%;
  display: inline-block;
  height: 2.5rem;
  line-height: 1;
  overflow: hidden;
  vertical-align: middle;
  width: 2.5rem;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor || Colors.Primary};
  ${(props) =>
    props.size === 'large' &&
    `
      font-size: 1.125rem;
      height: 3.2rem;
      width: 3.2rem;
    `};
  ${(props) =>
    props.size === 'medium' &&
    `
      font-size: 1rem;
      height: 2.5rem;
      width: 2.5rem;
    `};
  ${(props) =>
    props.size === 'small' &&
    `
      font-size: 0.725rem;
      height: 1.5rem;
      width: 1.5rem;
    `};
  ${(props) =>
    props.size === 'x-small' &&
    `
      font-size: 0.625rem;
      height: 1.25rem;
      width: 1.25rem;
    `};
`;
