import { NotificationsActive } from '@styled-icons/material';
import { Icon, IconProps } from './Icon';

export function NotificationIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <NotificationsActive />
    </Icon>
  );
}
