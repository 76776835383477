export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  IPv4: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

/** AIEmail */
export type AiEmail = {
  __typename?: 'AIEmail';
  body?: Maybe<Scalars['JSON']['output']>;
  category?: Maybe<EmailCategory>;
  /** Email of the contact */
  contactEmail: Scalars['String']['output'];
  /** Name of the contact */
  contactName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** Subject of the email */
  subject: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the task */
  uuid: Scalars['String']['output'];
};

export type AiEmailInputData = {
  body: Scalars['JSON']['input'];
  category?: InputMaybe<EmailCategory>;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type AiTaskInputData = {
  title: Scalars['String']['input'];
};

/** Auth0User */
export type Auth0User = {
  __typename?: 'Auth0User';
  email: Scalars['String']['output'];
  email_verified: Scalars['Boolean']['output'];
  jwt: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  state: Scalars['String']['output'];
  sub: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** Board */
export type Board = {
  __typename?: 'Board';
  availableDate?: Maybe<Scalars['String']['output']>;
  coreSkills?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  description?: Maybe<Scalars['String']['output']>;
  educationLevel?: Maybe<EducationLevel>;
  events?: Maybe<Array<Event>>;
  files?: Maybe<Array<File>>;
  followers?: Maybe<Array<User>>;
  industry?: Maybe<Industry>;
  interestLevel?: Maybe<InterestLevel>;
  isDeleted: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<Scalars['String']['output']>;
  locationCountry?: Maybe<Scalars['String']['output']>;
  locationLatitude?: Maybe<Scalars['Float']['output']>;
  locationLongitude?: Maybe<Scalars['Float']['output']>;
  locationPostCode?: Maybe<Scalars['String']['output']>;
  locationStreet?: Maybe<Scalars['String']['output']>;
  locationUrl?: Maybe<Scalars['String']['output']>;
  owner: User;
  permissions: Array<Scalars['String']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  resumes?: Maybe<Array<Resume>>;
  seniorityLevel?: Maybe<SeniorityLevel>;
  suggestedCoreSkills?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  uuid: Scalars['String']['output'];
  workRightEU?: Maybe<Scalars['Boolean']['output']>;
  workRightUK?: Maybe<Scalars['Boolean']['output']>;
};

export type BoardInputData = {
  availableDate?: InputMaybe<Scalars['String']['input']>;
  coreSkills?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  educationLevel?: InputMaybe<EducationLevel>;
  industry?: InputMaybe<Industry>;
  interestLevel?: InputMaybe<InterestLevel>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationPostCode?: InputMaybe<Scalars['String']['input']>;
  locationStreet?: InputMaybe<Scalars['String']['input']>;
  locationUrl?: InputMaybe<Scalars['String']['input']>;
  seniorityLevel?: InputMaybe<SeniorityLevel>;
  title: Scalars['String']['input'];
  workRightEU?: InputMaybe<Scalars['Boolean']['input']>;
  workRightUK?: InputMaybe<Scalars['Boolean']['input']>;
};

/** BoardPermission */
export type BoardPermission = {
  __typename?: 'BoardPermission';
  boardUuid: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  isDeleted: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type BoardsInputWhere = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConfirmMembershipInputData = {
  paymentIntentClientSecret: Scalars['String']['input'];
  paymentIntentId: Scalars['String']['input'];
  redirectStatus: Scalars['String']['input'];
};

/** Contact */
export type Contact = {
  __typename?: 'Contact';
  /** avatar of the contact */
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** email of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /** firstName of the contact */
  fullName?: Maybe<Scalars['String']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** linkedin of the contact */
  linkedin?: Maybe<Scalars['String']['output']>;
  /** phoneNumber of the contact */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** position of the contact */
  position?: Maybe<Scalars['String']['output']>;
  /** Internal sort key */
  relation: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the contact */
  uuid: Scalars['String']['output'];
};

export type ContactInputData = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  linkedin?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

/** Credit */
export type Credit = {
  __typename?: 'Credit';
  /** amount of the credit */
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** UUID of the credit */
  uuid: Scalars['String']['output'];
};

export enum DesignColorPalette {
  Background1 = 'BACKGROUND1',
  Background2 = 'BACKGROUND2',
  Dark1 = 'DARK1',
  Dark2 = 'DARK2',
  Default = 'DEFAULT',
  Light1 = 'LIGHT1',
  Light2 = 'LIGHT2',
  Standard1 = 'STANDARD1',
  Standard2 = 'STANDARD2'
}

export enum DesignDensity {
  Dense = 'DENSE',
  Loose = 'LOOSE',
  Normal = 'NORMAL'
}

export enum DesignFontFamily {
  Courier = 'COURIER',
  Helvetica = 'HELVETICA',
  Palatino = 'PALATINO',
  Times = 'TIMES'
}

export enum DesignTemplateName {
  Aquarius = 'AQUARIUS',
  Aries = 'ARIES',
  Cancer = 'CANCER',
  Capricorn = 'CAPRICORN',
  Gemini = 'GEMINI',
  Leo = 'LEO',
  Libra = 'LIBRA',
  Pisces = 'PISCES',
  Sagittarius = 'SAGITTARIUS',
  Scorpio = 'SCORPIO',
  Taurus = 'TAURUS',
  Virgo = 'VIRGO'
}

export enum EducationLevel {
  ALevelsGnvq = 'A_LEVELS_GNVQ',
  Btec = 'BTEC',
  CityGuilds = 'CITY_GUILDS',
  Diploma = 'DIPLOMA',
  GcseGnvqOLevels = 'GCSE_GNVQ_O_LEVELS',
  HndHnc = 'HND_HNC',
  MasterDegreeOrHigher = 'MASTER_DEGREE_OR_HIGHER',
  Phd = 'PHD',
  SeniorBusinessTechQualification = 'SENIOR_BUSINESS_TECH_QUALIFICATION',
  UniversityDegree = 'UNIVERSITY_DEGREE'
}

export enum EmailCategory {
  Consent = 'CONSENT',
  FollowUp = 'FOLLOW_UP',
  ThankYou = 'THANK_YOU'
}

export type EmailInputData = {
  email: Scalars['String']['input'];
  emailTemplate: EmailTemplate;
  params: Scalars['JSON']['input'];
  subject: Scalars['String']['input'];
};

export enum EmailTemplate {
  AddJobToBoard = 'ADD_JOB_TO_BOARD',
  Daily = 'DAILY',
  Generic = 'GENERIC',
  InviteBoardAgent = 'INVITE_BOARD_AGENT',
  InviteJobAgent = 'INVITE_JOB_AGENT',
  Weekly = 'WEEKLY'
}

export enum EmploymentType {
  Contract = 'CONTRACT',
  Internship = 'INTERNSHIP',
  PermanentFullTime = 'PERMANENT_FULL_TIME',
  PermanentPartTime = 'PERMANENT_PART_TIME',
  Temporary = 'TEMPORARY'
}

export enum EntityType {
  Board = 'BOARD',
  Event = 'EVENT',
  Job = 'JOB',
  Jobpost = 'JOBPOST'
}

/** Event */
export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  /** description of the event */
  description?: Maybe<Scalars['String']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  job?: Maybe<Job>;
  location?: Maybe<Scalars['String']['output']>;
  locationCity?: Maybe<Scalars['String']['output']>;
  locationCountry?: Maybe<Scalars['String']['output']>;
  locationLatitude?: Maybe<Scalars['Float']['output']>;
  locationLongitude?: Maybe<Scalars['Float']['output']>;
  locationPostCode?: Maybe<Scalars['String']['output']>;
  locationStreet?: Maybe<Scalars['String']['output']>;
  locationUrl?: Maybe<Scalars['String']['output']>;
  /** Meeting URL */
  meetingUrl?: Maybe<Scalars['String']['output']>;
  /** Internal sort key */
  relation: Scalars['String']['output'];
  startAt: Scalars['DateTime']['output'];
  type?: Maybe<EventType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the event */
  uuid: Scalars['String']['output'];
};

export type EventInputData = {
  description: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationLatitude?: InputMaybe<Scalars['Float']['input']>;
  locationLongitude?: InputMaybe<Scalars['Float']['input']>;
  locationPostCode?: InputMaybe<Scalars['String']['input']>;
  locationStreet?: InputMaybe<Scalars['String']['input']>;
  locationUrl?: InputMaybe<Scalars['String']['input']>;
  meetingUrl?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['String']['input'];
  type?: InputMaybe<EventType>;
};

export enum EventType {
  Face2Face = 'FACE2FACE',
  Other = 'OTHER',
  PhoneCall = 'PHONE_CALL',
  VideoCall = 'VIDEO_CALL'
}

export type EventsInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  periodRange?: InputMaybe<PeriodRange>;
  userUuid: Scalars['String']['input'];
};

export enum Feeling {
  Ecstatic = 'ECSTATIC',
  Happy = 'HAPPY',
  Normal = 'NORMAL',
  Sad = 'SAD'
}

/** File */
export type File = {
  __typename?: 'File';
  aiCoreSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiIsValidCoverLetter?: Maybe<Scalars['Boolean']['output']>;
  aiIsValidResume?: Maybe<Scalars['Boolean']['output']>;
  aiJobTitle?: Maybe<Scalars['String']['output']>;
  aiSoftSkills?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  filename: Scalars['String']['output'];
  formattedMimetype?: Maybe<Mime>;
  isConvertedToPDF?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isProcessed: Scalars['Boolean']['output'];
  mimetype: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  temporaryImageUrl?: Maybe<Scalars['String']['output']>;
  temporaryPDFUrl?: Maybe<Scalars['String']['output']>;
  temporaryUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  uploadSignedUrl?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type FixTypoData = {
  description: Scalars['String']['input'];
};

export type GenerateHightLightsData = {
  count?: InputMaybe<Scalars['Int']['input']>;
  key: Scalars['String']['input'];
  section: Scalars['String']['input'];
};

export type ImproveCoverLetterData = {
  count?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['JSON']['input'];
  writingStyle: WritingStyle;
};

export type ImproveDescriptionData = {
  count?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  writingStyle?: InputMaybe<WritingStyle>;
};

export type ImproveJobDescriptionData = {
  description: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImproveResumeData = {
  count?: InputMaybe<Scalars['Int']['input']>;
  field: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
  writingStyle: WritingStyle;
};

export enum Industry {
  AccountingFinance = 'ACCOUNTING_FINANCE',
  Administrative = 'ADMINISTRATIVE',
  ArtDesign = 'ART_DESIGN',
  BusinessSales = 'BUSINESS_SALES',
  Construction = 'CONSTRUCTION',
  Education = 'EDUCATION',
  Engineering = 'ENGINEERING',
  Entertainment = 'ENTERTAINMENT',
  GovernmentPublicAdministration = 'GOVERNMENT_PUBLIC_ADMINISTRATION',
  HealthcareServices = 'HEALTHCARE_SERVICES',
  HospitalityTourism = 'HOSPITALITY_TOURISM',
  HumanResources = 'HUMAN_RESOURCES',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Legal = 'LEGAL',
  Management = 'MANAGEMENT',
  Manufacturing = 'MANUFACTURING',
  Marketing = 'MARKETING',
  Other = 'OTHER',
  RetailCustomerService = 'RETAIL_CUSTOMER_SERVICE',
  ScienceResearch = 'SCIENCE_RESEARCH',
  SoftwareEngineering = 'SOFTWARE_ENGINEERING',
  TransportationLogistics = 'TRANSPORTATION_LOGISTICS'
}

export type InputSort = {
  direction: SortDirection;
  field: Scalars['String']['input'];
};

export enum InterestLevel {
  ActivelyLooking = 'ACTIVELY_LOOKING',
  JustBrowsing = 'JUST_BROWSING',
  OpenToOpportunity = 'OPEN_TO_OPPORTUNITY'
}

/** Job */
export type Job = {
  __typename?: 'Job';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agencyName?: Maybe<Scalars['String']['output']>;
  agentEmail?: Maybe<Scalars['String']['output']>;
  agentName?: Maybe<Scalars['String']['output']>;
  agentPhone?: Maybe<Scalars['String']['output']>;
  aiEmails?: Maybe<Array<AiEmail>>;
  aiIsPreparingCoverLetter?: Maybe<Scalars['Boolean']['output']>;
  aiIsProcessed?: Maybe<Scalars['Boolean']['output']>;
  aiMatchingNiceToHaveSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiMatchingNiceToHaveSkillsPercentage?: Maybe<Scalars['Float']['output']>;
  aiMatchingRequiredSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiMatchingRequiredSkillsPercentage?: Maybe<Scalars['Float']['output']>;
  aiMatchingSkillsPercentage?: Maybe<Scalars['Float']['output']>;
  aiMatchingSoftSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiMatchingSoftSkillsPercentage?: Maybe<Scalars['Float']['output']>;
  aiMotivationLetters?: Maybe<Array<Scalars['String']['output']>>;
  aiNiceToHaveSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiNonMatchingNiceToHaveSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiNonMatchingRequiredSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiNonMatchingSoftSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiRequiredSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiSoftSkills?: Maybe<Array<Scalars['String']['output']>>;
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  board: Board;
  boardUuid?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  companyAvatar?: Maybe<Scalars['String']['output']>;
  companyDistances?: Maybe<Scalars['JSON']['output']>;
  companyInfo?: Maybe<Scalars['String']['output']>;
  companyLinkedIn?: Maybe<Scalars['String']['output']>;
  companyLocation?: Maybe<Scalars['String']['output']>;
  companyLocationCity?: Maybe<Scalars['String']['output']>;
  companyLocationCountry?: Maybe<Scalars['String']['output']>;
  companyLocationLatitude?: Maybe<Scalars['Float']['output']>;
  companyLocationLongitude?: Maybe<Scalars['Float']['output']>;
  companyLocationPostCode?: Maybe<Scalars['String']['output']>;
  companyLocationStreet?: Maybe<Scalars['String']['output']>;
  companyLocationUrl?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  coverLetters?: Maybe<Array<CoverLetter>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  duration?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<EmploymentType>;
  events?: Maybe<Array<Event>>;
  feeling?: Maybe<Feeling>;
  files?: Maybe<Array<File>>;
  followers?: Maybe<Array<User>>;
  interviewedAt?: Maybe<Scalars['DateTime']['output']>;
  ir35?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  isThroughAgency?: Maybe<Scalars['Boolean']['output']>;
  jobDescription?: Maybe<Scalars['String']['output']>;
  jobRequirement?: Maybe<Scalars['String']['output']>;
  jobSeniorityLevel?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  jobUrl?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Note>>;
  offerAt?: Maybe<Scalars['DateTime']['output']>;
  owner: User;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  referralFee?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  remoteOption?: Maybe<RemoteOption>;
  resumes?: Maybe<Array<Resume>>;
  status?: Maybe<JobStatus>;
  tasks?: Maybe<Array<Task>>;
  temperature?: Maybe<Temperature>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userUuid?: Maybe<Scalars['String']['output']>;
  /** UUID of the job */
  uuid: Scalars['String']['output'];
};

export type JobFromContentInputData = {
  /** The unique id of the ws connection if exist */
  connectionId?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  /** The unique id of the task if exist */
  taskId?: InputMaybe<Scalars['String']['input']>;
};

export type JobFromJobPostInputData = {
  jobPostUuid: Scalars['String']['input'];
};

export type JobFromUrlInputData = {
  /** The unique id of the ws connection if exist */
  connectionId?: InputMaybe<Scalars['String']['input']>;
  /** The unique id of the task if exist */
  taskId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type JobInputData = {
  agencyName?: InputMaybe<Scalars['String']['input']>;
  agentEmail?: InputMaybe<Scalars['String']['input']>;
  agentName?: InputMaybe<Scalars['String']['input']>;
  agentPhone?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  companyInfo?: InputMaybe<Scalars['String']['input']>;
  companyLinkedIn?: InputMaybe<Scalars['String']['input']>;
  companyLocation?: InputMaybe<Scalars['String']['input']>;
  companyLocationCity?: InputMaybe<Scalars['String']['input']>;
  companyLocationCountry?: InputMaybe<Scalars['String']['input']>;
  companyLocationLatitude?: InputMaybe<Scalars['Float']['input']>;
  companyLocationLongitude?: InputMaybe<Scalars['Float']['input']>;
  companyLocationPostCode?: InputMaybe<Scalars['String']['input']>;
  companyLocationStreet?: InputMaybe<Scalars['String']['input']>;
  companyLocationUrl?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<EmploymentType>;
  feeling?: InputMaybe<Feeling>;
  ir35?: InputMaybe<Scalars['Boolean']['input']>;
  isThroughAgency?: InputMaybe<Scalars['Boolean']['input']>;
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  jobRequirement?: InputMaybe<Scalars['String']['input']>;
  jobSeniorityLevel?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  jobUrl?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  referralFee?: InputMaybe<Scalars['String']['input']>;
  remoteOption?: InputMaybe<RemoteOption>;
  status?: InputMaybe<JobStatus>;
};

/** JobPost */
export type JobPost = {
  __typename?: 'JobPost';
  agencyName?: Maybe<Scalars['String']['output']>;
  agentEmail?: Maybe<Scalars['String']['output']>;
  agentName?: Maybe<Scalars['String']['output']>;
  agentPhone?: Maybe<Scalars['String']['output']>;
  aiNiceToHaveSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiRequiredSkills?: Maybe<Array<Scalars['String']['output']>>;
  aiSoftSkills?: Maybe<Array<Scalars['String']['output']>>;
  company?: Maybe<Scalars['String']['output']>;
  companyInfo?: Maybe<Scalars['String']['output']>;
  companyLocation?: Maybe<Scalars['String']['output']>;
  companyLocationCity?: Maybe<Scalars['String']['output']>;
  companyLocationCountry?: Maybe<Scalars['String']['output']>;
  companyLocationLatitude?: Maybe<Scalars['Float']['output']>;
  companyLocationLongitude?: Maybe<Scalars['Float']['output']>;
  companyLocationPostCode?: Maybe<Scalars['String']['output']>;
  companyLocationStreet?: Maybe<Scalars['String']['output']>;
  companyLocationUrl?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Contact>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  duration?: Maybe<Scalars['String']['output']>;
  employmentType?: Maybe<EmploymentType>;
  expiredAt: Scalars['DateTime']['output'];
  files?: Maybe<Array<File>>;
  ir35?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  isRejected?: Maybe<Scalars['Boolean']['output']>;
  isThroughAgency?: Maybe<Scalars['Boolean']['output']>;
  jobDescription?: Maybe<Scalars['String']['output']>;
  jobRequirement?: Maybe<Scalars['String']['output']>;
  jobScrapeUrl?: Maybe<Scalars['String']['output']>;
  jobSeniorityLevel?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  jobUrl?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  rate?: Maybe<Scalars['String']['output']>;
  referralFee?: Maybe<Scalars['String']['output']>;
  remoteOption?: Maybe<RemoteOption>;
  status?: Maybe<JobPostStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userUuid: Scalars['String']['output'];
  /** UUID of the job */
  uuid: Scalars['String']['output'];
};

export type JobPostFromUrlInputData = {
  /** The unique id of the ws connection if exist */
  connectionId?: InputMaybe<Scalars['String']['input']>;
  /** The unique id of the task if exist */
  taskId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type JobPostInputData = {
  agencyName?: InputMaybe<Scalars['String']['input']>;
  agentEmail?: InputMaybe<Scalars['String']['input']>;
  agentName?: InputMaybe<Scalars['String']['input']>;
  agentPhone?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  companyInfo?: InputMaybe<Scalars['String']['input']>;
  companyLocation?: InputMaybe<Scalars['String']['input']>;
  companyLocationCity?: InputMaybe<Scalars['String']['input']>;
  companyLocationCountry?: InputMaybe<Scalars['String']['input']>;
  companyLocationLatitude?: InputMaybe<Scalars['Float']['input']>;
  companyLocationLongitude?: InputMaybe<Scalars['Float']['input']>;
  companyLocationPostCode?: InputMaybe<Scalars['String']['input']>;
  companyLocationStreet?: InputMaybe<Scalars['String']['input']>;
  companyLocationUrl?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  employmentType?: InputMaybe<EmploymentType>;
  ir35?: InputMaybe<Scalars['Boolean']['input']>;
  isThroughAgency?: InputMaybe<Scalars['Boolean']['input']>;
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  jobRequirement?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  jobUrl?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  referralFee?: InputMaybe<Scalars['String']['input']>;
  remoteOption?: InputMaybe<RemoteOption>;
};

export enum JobPostStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  PendingReview = 'PENDING_REVIEW',
  Published = 'PUBLISHED',
  ReadyForScrape = 'READY_FOR_SCRAPE',
  Rejected = 'REJECTED',
  Scraped = 'SCRAPED',
  Unpublished = 'UNPUBLISHED'
}

export type JobPostsInputWhere = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export enum JobStatus {
  Accepted = 'ACCEPTED',
  Applied = 'APPLIED',
  Closed = 'CLOSED',
  Interview = 'INTERVIEW',
  Offer = 'OFFER',
  Scrapped = 'SCRAPPED',
  Wishlist = 'WISHLIST'
}

export type JobsInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isRejected?: InputMaybe<Scalars['Boolean']['input']>;
  userUuid: Scalars['String']['input'];
};

/** Mail */
export type Mail = {
  __typename?: 'Mail';
  /** body */
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** email */
  email?: Maybe<Scalars['String']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** subject */
  subject?: Maybe<Scalars['String']['output']>;
  /** UUID of the email */
  uuid: Scalars['String']['output'];
};

export type MailInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
};

/** Membership */
export type Membership = {
  __typename?: 'Membership';
  clientSecret: Scalars['String']['output'];
  invoice?: Maybe<Scalars['JSON']['output']>;
  subscriptionId: Scalars['String']['output'];
};

export type MembershipInputData = {
  subscriptionPriceId: Scalars['String']['input'];
};

export enum Mime {
  Doc = 'DOC',
  File = 'FILE',
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Pages = 'PAGES',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type Mutation = {
  __typename?: 'Mutation';
  archiveAIEmail: AiEmail;
  archiveBoard: Board;
  archiveContact: Contact;
  archiveCoverLetter: CoverLetter;
  archiveEvent: Event;
  archiveFile?: Maybe<File>;
  archiveJob: Job;
  archiveJobPost: JobPost;
  archiveNote: Note;
  archiveResume: Resume;
  archiveTask: Task;
  cancelMembership?: Maybe<Scalars['Boolean']['output']>;
  completeTask: Task;
  confirmMembership?: Maybe<Scalars['Boolean']['output']>;
  copyResume?: Maybe<Resume>;
  createAIEmail?: Maybe<AiEmail>;
  createAITask?: Maybe<Task>;
  createBoard?: Maybe<Board>;
  createContact?: Maybe<Contact>;
  createCoverLetter?: Maybe<CoverLetter>;
  createEvent?: Maybe<Event>;
  createJob?: Maybe<Job>;
  createJobFromContent?: Maybe<Scalars['Boolean']['output']>;
  createJobFromJobPost?: Maybe<Job>;
  createJobFromUrl?: Maybe<Scalars['Boolean']['output']>;
  createJobPost?: Maybe<Job>;
  createJobPostFromUrl?: Maybe<Job>;
  createMembership?: Maybe<Membership>;
  createNote?: Maybe<Note>;
  createResume?: Maybe<Resume>;
  createResumeFromFile?: Maybe<Resume>;
  createTask?: Maybe<Task>;
  createUploadSignedUrl?: Maybe<File>;
  dangerDeleteFullAccount?: Maybe<Scalars['Boolean']['output']>;
  downloadAIEmail: AiEmail;
  fixTypo?: Maybe<Scalars['String']['output']>;
  generateHightLights?: Maybe<Scalars['JSON']['output']>;
  improveCoverLetter?: Maybe<Scalars['JSON']['output']>;
  improveDescription?: Maybe<Array<Scalars['String']['output']>>;
  improveJobDescription?: Maybe<Scalars['String']['output']>;
  improveResume?: Maybe<Scalars['JSON']['output']>;
  inviteUserOnBoard: Scalars['Boolean']['output'];
  inviteUserOnJob: Scalars['Boolean']['output'];
  logout: Scalars['Boolean']['output'];
  passwordlessSignIn: Scalars['Boolean']['output'];
  prepareCoverLetter?: Maybe<Scalars['Boolean']['output']>;
  prepareEmail?: Maybe<Scalars['Boolean']['output']>;
  publishBoard: Board;
  publishJob: Job;
  publishJobPost: JobPost;
  renderCoverLetter?: Maybe<Scalars['String']['output']>;
  renderResume?: Maybe<Scalars['String']['output']>;
  restoreMembership?: Maybe<Scalars['Boolean']['output']>;
  signInConfirm?: Maybe<Auth0User>;
  tailorResume?: Maybe<Resume>;
  tailorResumeFromFile?: Maybe<Resume>;
  unfollowBoard: Scalars['Boolean']['output'];
  unfollowJob: Scalars['Boolean']['output'];
  updateAIEmail?: Maybe<AiEmail>;
  updateBoard?: Maybe<Board>;
  updateContact?: Maybe<Contact>;
  updateCoverLetter?: Maybe<CoverLetter>;
  updateEvent?: Maybe<Event>;
  updateJob?: Maybe<Job>;
  updateJobPost?: Maybe<JobPost>;
  updateJobStatus?: Maybe<Job>;
  updateNote?: Maybe<Note>;
  updateResume?: Maybe<Resume>;
  updateTask?: Maybe<Task>;
  updateUser?: Maybe<User>;
};


export type MutationArchiveAiEmailArgs = {
  aiEmailUuid: Scalars['String']['input'];
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveBoardArgs = {
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveContactArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  contactUuid: Scalars['String']['input'];
  entityType: EntityType;
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveCoverLetterArgs = {
  boardUuid: Scalars['String']['input'];
  coverLetterUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveEventArgs = {
  boardUuid: Scalars['String']['input'];
  eventUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveFileArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  entityType: EntityType;
  fileUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveJobArgs = {
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
};


export type MutationArchiveJobPostArgs = {
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
};


export type MutationArchiveNoteArgs = {
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  noteUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationArchiveResumeArgs = {
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid: Scalars['String']['input'];
};


export type MutationArchiveTaskArgs = {
  boardUuid: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  taskUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCompleteTaskArgs = {
  boardUuid: Scalars['String']['input'];
  isCompleted: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  taskUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationConfirmMembershipArgs = {
  data: ConfirmMembershipInputData;
};


export type MutationCopyResumeArgs = {
  boardUuid: Scalars['String']['input'];
  resumeUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateAiEmailArgs = {
  boardUuid: Scalars['String']['input'];
  data: AiEmailInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateAiTaskArgs = {
  boardUuid: Scalars['String']['input'];
  data: AiTaskInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateBoardArgs = {
  data: BoardInputData;
};


export type MutationCreateContactArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  data: ContactInputData;
  entityType: EntityType;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateCoverLetterArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  state?: InputMaybe<Scalars['JSON']['input']>;
  userUuid: Scalars['String']['input'];
};


export type MutationCreateEventArgs = {
  boardUuid: Scalars['String']['input'];
  data: EventInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobArgs = {
  boardUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobFromContentArgs = {
  boardUuid: Scalars['String']['input'];
  data: JobFromContentInputData;
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobFromJobPostArgs = {
  boardUuid: Scalars['String']['input'];
  data: JobFromJobPostInputData;
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobFromUrlArgs = {
  boardUuid: Scalars['String']['input'];
  data: JobFromUrlInputData;
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobPostArgs = {
  userUuid: Scalars['String']['input'];
};


export type MutationCreateJobPostFromUrlArgs = {
  data: JobPostFromUrlInputData;
};


export type MutationCreateMembershipArgs = {
  data: MembershipInputData;
};


export type MutationCreateNoteArgs = {
  boardUuid: Scalars['String']['input'];
  data: NoteInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateResumeArgs = {
  boardUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateResumeFromFileArgs = {
  boardUuid: Scalars['String']['input'];
  fileUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateTaskArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  data: TaskInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationCreateUploadSignedUrlArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  entityType: EntityType;
  filename: Scalars['String']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  lastModified: Scalars['Float']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationDownloadAiEmailArgs = {
  aiEmailUuid: Scalars['String']['input'];
  boardUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationFixTypoArgs = {
  data: FixTypoData;
};


export type MutationGenerateHightLightsArgs = {
  boardUuid: Scalars['String']['input'];
  data: GenerateHightLightsData;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationImproveCoverLetterArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  data: ImproveCoverLetterData;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImproveDescriptionArgs = {
  data: ImproveDescriptionData;
};


export type MutationImproveJobDescriptionArgs = {
  data: ImproveJobDescriptionData;
};


export type MutationImproveResumeArgs = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  data: ImproveResumeData;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteUserOnBoardArgs = {
  boardUuid: Scalars['String']['input'];
  data: EmailInputData;
  userUuid: Scalars['String']['input'];
};


export type MutationInviteUserOnJobArgs = {
  boardUuid: Scalars['String']['input'];
  data: EmailInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationPasswordlessSignInArgs = {
  email: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  state: Scalars['String']['input'];
};


export type MutationPrepareCoverLetterArgs = {
  boardUuid: Scalars['String']['input'];
  connectionId?: InputMaybe<Scalars['String']['input']>;
  jobUuid: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationPrepareEmailArgs = {
  boardUuid: Scalars['String']['input'];
  category: EmailCategory;
  connectionId?: InputMaybe<Scalars['String']['input']>;
  jobUuid: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationPublishBoardArgs = {
  boardUuid: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationPublishJobArgs = {
  boardUuid: Scalars['String']['input'];
  isPublic: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationPublishJobPostArgs = {
  isPublished: Scalars['Boolean']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationRenderCoverLetterArgs = {
  boardUuid: Scalars['String']['input'];
  coverLetterUuid?: InputMaybe<Scalars['String']['input']>;
  jobUuid: Scalars['String']['input'];
  state?: InputMaybe<Scalars['JSON']['input']>;
  userUuid: Scalars['String']['input'];
};


export type MutationRenderResumeArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['JSON']['input']>;
  userUuid: Scalars['String']['input'];
};


export type MutationSignInConfirmArgs = {
  accessToken: Scalars['String']['input'];
  state: Scalars['String']['input'];
};


export type MutationTailorResumeArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  resumeUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationTailorResumeFromFileArgs = {
  boardUuid: Scalars['String']['input'];
  fileUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUnfollowBoardArgs = {
  boardUuid: Scalars['String']['input'];
  followingUserUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUnfollowJobArgs = {
  boardUuid: Scalars['String']['input'];
  followingUserUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateAiEmailArgs = {
  aiEmailUuid: Scalars['String']['input'];
  boardUuid: Scalars['String']['input'];
  data: AiEmailInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateBoardArgs = {
  boardUuid: Scalars['String']['input'];
  data: BoardInputData;
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateContactArgs = {
  boardUuid: Scalars['String']['input'];
  contactUuid: Scalars['String']['input'];
  data: ContactInputData;
  entityType: EntityType;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateCoverLetterArgs = {
  boardUuid: Scalars['String']['input'];
  coverLetterUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  state: Scalars['JSON']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateEventArgs = {
  boardUuid: Scalars['String']['input'];
  data: EventInputData;
  eventUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateJobArgs = {
  boardUuid: Scalars['String']['input'];
  data: JobInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateJobPostArgs = {
  data: JobPostInputData;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateJobStatusArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  status: JobStatus;
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateNoteArgs = {
  boardUuid: Scalars['String']['input'];
  data: NoteInputData;
  jobUuid: Scalars['String']['input'];
  noteUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateResumeArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid: Scalars['String']['input'];
  state: Scalars['JSON']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateTaskArgs = {
  boardUuid: Scalars['String']['input'];
  data: TaskInputData;
  jobUuid: Scalars['String']['input'];
  taskUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  data: UserInputData;
};

/** Note */
export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** body */
  description?: Maybe<Scalars['String']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** body */
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the contact */
  uuid: Scalars['String']['output'];
};

export type NoteInputData = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NotesInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  data?: Maybe<Scalars['JSON']['output']>;
  /** description */
  description?: Maybe<Scalars['String']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** UUID of the contact */
  uuid: Scalars['String']['output'];
};

export type NotificationsInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid: Scalars['String']['input'];
};

export enum PeriodRange {
  Day = 'DAY',
  Month = 'MONTH',
  Overdue = 'OVERDUE',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum Plan {
  CancelledMonthly = 'CANCELLED_MONTHLY',
  CancelledQuarterly = 'CANCELLED_QUARTERLY',
  CancelledTrial = 'CANCELLED_TRIAL',
  CancelledYearly = 'CANCELLED_YEARLY',
  Free = 'FREE',
  Monthly = 'MONTHLY',
  PausedMonthly = 'PAUSED_MONTHLY',
  PausedQuarterly = 'PAUSED_QUARTERLY',
  PausedYearly = 'PAUSED_YEARLY',
  Quarterly = 'QUARTERLY',
  Trial = 'TRIAL',
  Yearly = 'YEARLY'
}

export type ProductsInputWhere = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  board?: Maybe<Board>;
  boards: Array<Board>;
  downloadSignedUrl?: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  followingBoards: Array<Board>;
  getAllUsers?: Maybe<Array<User>>;
  invoices?: Maybe<Array<StripeInvoice>>;
  job?: Maybe<Job>;
  jobPost?: Maybe<JobPost>;
  jobPosts: Array<JobPost>;
  jobs: Array<Job>;
  mails: Array<Mail>;
  me?: Maybe<User>;
  notes: Array<Note>;
  notifications: Array<Notification>;
  products: Array<StripeProduct>;
  publicBoard?: Maybe<Board>;
  publicJob?: Maybe<Job>;
  publicResume?: Maybe<Resume>;
  resume?: Maybe<Resume>;
  subscription?: Maybe<StripeSubscription>;
  tasks: Array<Task>;
};


export type QueryBoardArgs = {
  boardUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryBoardsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort: InputSort;
  where: BoardsInputWhere;
};


export type QueryDownloadSignedUrlArgs = {
  boardUuid: Scalars['String']['input'];
  fileUuid: Scalars['String']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: EventsInputWhere;
};


export type QueryFollowingBoardsArgs = {
  sort: InputSort;
};


export type QueryJobArgs = {
  boardUuid: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryJobPostArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryJobPostsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: JobPostsInputWhere;
};


export type QueryJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: JobsInputWhere;
};


export type QueryMailsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: MailInputWhere;
};


export type QueryNotesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: NotesInputWhere;
};


export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: NotificationsInputWhere;
};


export type QueryProductsArgs = {
  where: ProductsInputWhere;
};


export type QueryPublicBoardArgs = {
  boardUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryPublicJobArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryPublicResumeArgs = {
  boardUuid: Scalars['String']['input'];
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryResumeArgs = {
  boardUuid: Scalars['String']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  resumeUuid: Scalars['String']['input'];
  userUuid: Scalars['String']['input'];
};


export type QueryTasksArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InputSort>;
  where: TasksInputWhere;
};

export enum RemoteOption {
  Flexible = 'FLEXIBLE',
  FourDays = 'FOUR_DAYS',
  FullyRemote = 'FULLY_REMOTE',
  NoRemote = 'NO_REMOTE',
  OneDay = 'ONE_DAY',
  ThreeDays = 'THREE_DAYS',
  TwoDays = 'TWO_DAYS'
}

/** Resume */
export type Resume = {
  __typename?: 'Resume';
  boardUuid?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  isDeleted: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  userUuid?: Maybe<Scalars['String']['output']>;
  /** UUID of the job */
  uuid: Scalars['String']['output'];
};

export enum SeniorityLevel {
  Entry = 'ENTRY',
  Junior = 'JUNIOR',
  Lead = 'LEAD',
  Mid = 'MID',
  Senior = 'SENIOR'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Invoice */
export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  /** Amount paid */
  amountPaid: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Hosted invoice url */
  hostedInvoiceUrl: Scalars['String']['output'];
  /** Internal id */
  id: Scalars['String']['output'];
  /** Invoice pdf */
  invoicePdf: Scalars['String']['output'];
  /** Number */
  number: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  /** Status */
  status: Scalars['String']['output'];
  /** Total */
  total: Scalars['Int']['output'];
};

/** Stripe Product */
export type StripeProduct = {
  __typename?: 'StripeProduct';
  /** Whether the price can be used for new purchases. */
  active: Scalars['Boolean']['output'];
  /** Three-letter ISO currency code, in lowercase. Must be a supported currency. */
  currency: Scalars['String']['output'];
  /** Internal partition key */
  priceId: Scalars['String']['output'];
  /** The product’s name, meant to be displayable to the customer. */
  productName: Scalars['String']['output'];
  /** How frequently the subscription interval occurs, in months. */
  recurrence?: Maybe<Scalars['String']['output']>;
  /** Number of trial period days granted when subscribing a customer to this price. */
  trialPeriodDays?: Maybe<Scalars['Int']['output']>;
  /** The unit amount in cents to be charged, represented as a whole integer if possible. */
  unitAmount: Scalars['Int']['output'];
  /** The unit amount in cents to be charged, represented as a decimal string with at most 12 decimal places. */
  unitAmountDecimal: Scalars['Float']['output'];
};

/** Stripe Subscription */
export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  /** Cancel at period end */
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  /** Internal id */
  id: Scalars['String']['output'];
  /** Status */
  status?: Maybe<Scalars['String']['output']>;
};

/** Task */
export type Task = {
  __typename?: 'Task';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** body */
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** isFullDay */
  isFullDay?: Maybe<Scalars['Boolean']['output']>;
  isOverdue: Scalars['Boolean']['output'];
  job?: Maybe<Job>;
  order?: Maybe<Scalars['Int']['output']>;
  /** priority */
  priority?: Maybe<Scalars['String']['output']>;
  /** Internal sort key */
  relation: Scalars['String']['output'];
  /** body */
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the task */
  uuid: Scalars['String']['output'];
};

export type TaskInputData = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  isFullDay?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type TasksInputWhere = {
  boardUuid?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  periodRange?: InputMaybe<PeriodRange>;
  userUuid: Scalars['String']['input'];
};

export enum Temperature {
  Cold = 'COLD',
  Hot = 'HOT',
  Normal = 'NORMAL'
}

/** User */
export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credits?: Maybe<Scalars['Int']['output']>;
  creditsHistory?: Maybe<Scalars['JSON']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  enableEmailDailySummaryEmail?: Maybe<Scalars['Boolean']['output']>;
  enableEmailNotification?: Maybe<Scalars['Boolean']['output']>;
  enableEmailWeeklySummaryEmail?: Maybe<Scalars['Boolean']['output']>;
  enableMarketingEmail?: Maybe<Scalars['Boolean']['output']>;
  enablePushNotification?: Maybe<Scalars['Boolean']['output']>;
  enableSmsNotification?: Maybe<Scalars['Boolean']['output']>;
  extraCredits?: Maybe<Scalars['Int']['output']>;
  hadTrial?: Maybe<Scalars['Boolean']['output']>;
  hasGoogleAuth?: Maybe<Scalars['Boolean']['output']>;
  hasGoogleAuthAt?: Maybe<Scalars['DateTime']['output']>;
  hasLinkedinAuth?: Maybe<Scalars['Boolean']['output']>;
  hasLinkedinAuthAt?: Maybe<Scalars['DateTime']['output']>;
  hasPasswordlessAuth?: Maybe<Scalars['Boolean']['output']>;
  hasPasswordlessAuthAt?: Maybe<Scalars['DateTime']['output']>;
  ipV4?: Maybe<Scalars['IPv4']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  isMember: Scalars['Boolean']['output'];
  membershipPlan?: Maybe<Plan>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['String']['output'];
};

export type UserInputData = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  enableEmailDailySummaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enableEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
  enableEmailWeeklySummaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enableMarketingEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enablePushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  enableSmsNotification?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum WritingStyle {
  AddHighlights = 'ADD_HIGHLIGHTS',
  Expand = 'EXPAND',
  Rewrite = 'REWRITE',
  Shorten = 'SHORTEN',
  Spellcheck = 'SPELLCHECK',
  Summarize = 'SUMMARIZE'
}

/** coverLetter */
export type CoverLetter = {
  __typename?: 'coverLetter';
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  /** Internal partition key */
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Internal sort key */
  relation: Scalars['String']['output'];
  state?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** UUID of the AI Cover Letter */
  uuid: Scalars['String']['output'];
};
