import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useRouter } from '../../../hooks/useRouter';
import useElementFocus from '../../../hooks/useElementFocus';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';
import { zIndex } from '../../../styles/zIndex';
import { FontWeights, BodyStyle2 } from '../../../styles/fonts';

import { useAuthContext } from '../../../contexts/Auth0Context';
import { UserMenuSettings } from './UserMenuSettings';
import { useUserContext } from '../../../contexts/UserContext/UserContext';
import { Avatar } from '../../atoms/Avatar';
import { Menu } from '../../atoms/Menu';
import { ChevronDownIcon } from '../../atoms/Icons';
import { Copy } from '../../atoms/Typography';

export const UserMenu = () => {
  const user = useUserContext();
  const { authUser } = useAuthContext();
  const { location } = useRouter();
  const [showMenu, setShowMenu] = useState(false);
  const close = () => setShowMenu(false);
  const toggle = () => setShowMenu(!showMenu);
  const { handleFocus, handleBlur } = useElementFocus({ onBlur: close });
  useEffect(close, [location.pathname]);

  return (
    <Wrapper onFocus={handleFocus} onBlur={handleBlur} tabIndex={-1}>
      <ProfileDetails onClick={toggle} open={showMenu}>
        <IconNameWrapper>
          <Avatar url={authUser.picture} image={user?.avatar} size="small"></Avatar>
          <NameWrapper>
            <div>{user?.name ? user?.name : user?.nickname}</div>
            <Copy styleLevel={3} isItalic={true}>
              {user?.email}
            </Copy>
          </NameWrapper>
        </IconNameWrapper>
        {/* <pre>{JSON.stringify(authUser.picture, null, 4)}</pre> */}
        {/* <pre>{JSON.stringify(user?.avatar, null, 4)}</pre> */}
        <Arrow open={showMenu} />
      </ProfileDetails>
      {showMenu && (
        <StyledMenu key="menu">
          <UserMenuSettings />
        </StyledMenu>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  outline: none;
  height: 100%;
  height: 60px;
`;

const ProfileDetails = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: ${Colors.PrimaryDark};
  }
  ${({ open }) => open && `background-color: ${Colors.PrimaryDark};`}
`;

const IconNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NameWrapper = styled.div`
  ${media.phone`
    display: none;
  `}
  > div {
    ${BodyStyle2};
    width: 100%;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${FontWeights.Bold};
  }
`;

const Arrow = styled(ChevronDownIcon)<{ open?: boolean }>`
  flex: 0 0 30px;
  transition: transform 0.2s ease-out;
  ${({ open }) => open && 'transform: rotate(180deg)'};
`;

const StyledMenu = styled(Menu)`
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  z-index: ${zIndex.UserMenu};
  ${media.phone`
    width: 200px;
  `};
`;
