import styled from 'styled-components';

import { media } from '../../../styles/media';

export const Layout = styled.div`
  position: relative;
  margin: auto;
  width: 98%;
  min-height: 70vh;
  padding-inline: var(--right-left-global-padding);
  margin-block-end: 32px;
  margin-top: 32px;
  max-width: 1600px;

  ${media.from_tablet`
    margin-top: 8px;
    width: 95%;
    min-height: 80vh;
    margin-block-end: 64px;
  `};

  ${media.from_laptop`
    margin-top: 8px;
    width: 96%;
    min-height: 90vh;
    margin-block-end: 128px;
  `};
`;

export const LayoutFullWidthMargin = styled.div`
  position: relative;
  min-height: 70vh;
  padding-inline: var(--right-left-global-padding);
  margin-block-end: 32px;

  ${media.from_tablet`
    min-height: 80vh;
    margin-block-end: 64px;
  `};

  ${media.from_laptop`
    min-height: 90vh;
    margin-block-end: 128px;
  `};
`;

export const LayoutFullWidth = styled.div`
  /* border: 1px solid red; */
  position: relative;
  min-height: 70vh;

  ${media.from_tablet`
    min-height: 80vh;
  `};

  ${media.from_laptop`
    min-height: 90vh;
  `};
`;

export const LayoutCentered = styled.div`
  padding-block-start: 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 80vh;
`;

export const SidebarLayout = styled.div`
  display: flex;
  flex-direction: row;
  ${media.phone`
    flex-direction: column;
    margin-top: 20px;
  `}
`;

export const Sidebar = styled.div`
  max-width: 100%;

  ${media.from_mobile`
    border-right: 1px solid #D3DED3;
  `}
`;

export const SidebarContent = styled.div`
  padding-inline: var(--right-left-global-padding);
  flex: 1 1 100px;
  margin-bottom: 64px;
`;
