import { useParams, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { useMemo } from 'react';

// // Usage
// function MyComponent() {
//   // Get the router object
//   const router = useRouter();

//   // Get value from query string (?postId=123) or route param (/:postId)
//   console.log(router.query.postId);

//   // Get current pathname
//   console.log(router.pathname);

//   // Navigate with with router.push()
//   return <button onClick={e => router.push('/about')}>About</button>;
// }

interface Params {
  boardUuid?: string;
  jobUuid?: string;
  resumeUuid?: string;
  stepId?: string;
  redirect?: string;
  code?: string;
  userUuid?: string;
  payment_intent?: string;
  payment_intent_client_secret?: string;
  redirect_status?: string;
}

export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const firstHash = location.hash ? location.hash.split('#')[1] : '';
  const match = useResolvedPath('').pathname;
  const params = useParams() as Params;
  const urlParams = new URLSearchParams(location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      navigate,
      pathname: location.pathname,
      hash: location.hash,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...parsedParams,
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      firstHash,
      routerState: location.state,
    };
  }, [location, match, params, navigate]);
}
