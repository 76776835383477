import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';
import simplelogo from './logo-primary-192.svg';


export const Logo: React.FC = () => {
  return (
    <LogoWrapper>
      <img src={logo} alt="SVG logo image" />
    </LogoWrapper>
  );
};

export const SimpleLogo: React.FC = () => {
  return (
    <LogoWrapper>
      <img src={simplelogo} alt="SVG logo image" />
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
`;
