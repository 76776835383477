import { Link } from 'react-router-dom';

import { useAuthContext } from '../../../contexts/Auth0Context';
import { useRouter } from '../../../hooks/useRouter';

import { Urls } from '../../../routes';

import { SettingsIcon, LogOutIcon, DesktopIcon, HomeIcon, SearchIcon, StarIcon } from '../../atoms/Icons';
import { Menu, MenuItem } from '../../atoms/Menu';
import { useUserContext } from '../../../contexts/UserContext';

export const UserMenuSettings = () => {
  const { signOut } = useAuthContext();
  const { location } = useRouter();
  const user = useUserContext();

  return (
    <Menu>
      <MenuItem icon={<HomeIcon size={1.8} />} isSelected={location.pathname === `/${Urls.Dashboard}`}>
        <Link to={`/${Urls.Dashboard}`}>Dashboard</Link>
      </MenuItem>
      <MenuItem icon={<DesktopIcon size={1.8} />} isSelected={location.pathname === `/${Urls.Boards}`}>
        <Link to={`/${Urls.Boards}`}>Boards</Link>
      </MenuItem>
      <MenuItem icon={<SettingsIcon size={1.8} />} isSelected={location.pathname === `/${Urls.Settings}`}>
        <Link to={`/${Urls.Settings}`}>Settings</Link>
      </MenuItem>
      {user?.isMember && (
        <MenuItem icon={<SearchIcon size={1.8} />} isSelected={location.pathname === `/${Urls.Settings}`}>
          <Link to={`/${Urls.JobPosts}`}>Job Posts</Link>
        </MenuItem>
      )}
      {user?.isAdmin && (
        <MenuItem
          icon={<StarIcon size={1.8} />}
          isSelected={location.pathname === `/${Urls.Admin}/${Urls.AdminJobPosts}`}
        >
          <Link to={`/${Urls.Admin}/${Urls.AdminJobPosts}`}>Admin</Link>
        </MenuItem>
      )}
      <MenuItem icon={<LogOutIcon size={1.8} />}>
        <Link to="#" onClick={signOut}>
          Log out
        </Link>
      </MenuItem>
    </Menu>
  );
};
