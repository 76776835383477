import styled, { css } from 'styled-components';

import { BaseFontStyles, BodyStyle4, BodyStyle3, BodyStyle2, BodyStyle1 } from '../../../styles/fonts';
import { Colors } from '../../../styles/colors';

export const Copy: React.FunctionComponent<CopyProps> = ({
  children,
  styleLevel = 1,
  marginBottom = 0,
  isBold = false,
  isItalic = false,
  color = Colors.Black,
}: CopyProps) => {
  return (
    <CopyWrapper
      color={color}
      $styleLevel={styleLevel}
      $marginBottom={marginBottom}
      $isBold={isBold}
      $isItalic={isItalic}
    >
      {children}
    </CopyWrapper>
  );
};

export const CopyColoredSpan = styled.span<{
  color?: string;
}>`
  color: ${({ color = Colors.PrimaryDarkest }) => color};
  font-weight: bold;
`;

export const CopyLineWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const CopyWrapper = styled.div<{
  $styleLevel: number;
  $marginBottom: number;
  $isBold: boolean;
  $isItalic: boolean;
  color: string;
}>`
  ${BaseFontStyles}

  font-style: ${({ $isItalic = false }) => ($isItalic ? 'italic' : 'normal')};

  ${({ color, $isBold, $isItalic }) => {
    if (color) {
      return css`
        color: ${color};
      `;
    }
    if ($isBold) {
      return css`
        font-weight: bold;
      `;
    }
  }};

  margin-bottom: ${({ $marginBottom }) => {
    return $marginBottom ? `${$marginBottom}px` : 0;
  }};

  ${({ $styleLevel }) => {
    if ($styleLevel === 4) {
      return css`
        ${BodyStyle4}
      `;
    }
    if ($styleLevel === 3) {
      return css`
        ${BodyStyle3}
      `;
    }
    if ($styleLevel === 2) {
      return css`
        ${BodyStyle2}
      `;
    }
    if ($styleLevel === 1) {
      return css`
        ${BodyStyle1}
      `;
    }
  }}
`;

export interface CopyProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly marginBottom?: number;
  readonly styleLevel?: number;
  readonly color?: string;
  readonly isBold?: boolean;
  readonly isItalic?: boolean;
}
