import styled from 'styled-components';
import Paper from '@mui/material/Paper';

import { media } from '../../../styles/media';

export const PaperExtended = ({ elevation = 5, children }: PaperExtendedProps) => {
  return <PaperWrapper elevation={elevation}>{children}</PaperWrapper>;
};

const PaperWrapper = styled(Paper)`
  padding: 8px;
  margin: 8px 0;
  height: 100%;

  ${media.from_mobile`
    padding: 16px;
  `}

  ${media.from_tablet`
    padding: 32px;
  `}
`;

type PaperExtendedProps = { elevation?: number; children: any };
