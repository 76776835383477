import React, { useContext } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { Colors } from '../../styles/colors';

// @ts-ignore
export const useThemeContext = () => useContext(MuiThemeProvider);

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Contrast,
      light: Colors.ContrastLight,
      dark: Colors.ContrastDark,
    },
    secondary: {
      main: Colors.Primary,
      light: Colors.PrimaryLight,
      dark: Colors.PrimaryDark,
    },
    error: {
      main: Colors.Error,
      light: Colors.ErrorLight,
      dark: Colors.ErrorDark,
    },
    success: {
      main: Colors.Primary,
      light: Colors.PrimaryLightest,
      dark: Colors.PrimaryDarkest,
    },
  },
});

export const ThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
