import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const GlobalLoader: React.FC = () => {
  return (
    <Wrapper>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color={Colors.Primary}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </Wrapper>
  );
};

export const SmallLoader: React.FC = ({ color = Colors.Contrast }: any) => {
  return (
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color={color}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      visible={true}
    />
  );
};

export const TinyLoader: React.FC = ({ color = Colors.Contrast }: any) => {
  return (
    <ThreeDots
      height="28"
      width="32"
      radius="9"
      color={color}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      visible={true}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
