import { toast } from 'react-toastify';
import Pusher, { Channel } from 'pusher-js';
import React, { createContext, useContext, useEffect } from 'react';

import { useUserContext } from '../UserContext';

const pushContext = createContext<{
  notification: number;
  setNotification: React.Dispatch<React.SetStateAction<number>>;
  refetchQueries: {
    board_view: string;
    taskId: string;
  };
  pushState: any;
  isDrawerOpen: boolean;
  clearPushState: () => void;
  clearRefetchQueries: () => void;
}>({
  notification: 0,
  setNotification: () => {},
  refetchQueries: {
    board_view: '',
    taskId: '',
  },
  pushState: null,
  isDrawerOpen: false,
  clearPushState: () => {},
  clearRefetchQueries: () => {},
});
const PUSH_KEY = import.meta.env.VITE_PUSHER_KEY;

// const ENV = import.meta.env.VITE_APP_ENV;
// Enable pusher logging - don't include this in production
// if (ENV === 'local') {
//   Pusher.logToConsole = true;
// }

export const PushProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [pushState, setInternalState] = React.useState<any>(null);
  const pusherRef = React.useRef<Pusher | null>(null);
  const pusherChannelRef = React.useRef<Channel | null>(null);
  const [notification, setNotification] = React.useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [refetchQueries, setRefetchQueries] = React.useState<{
    board_view: string;
    taskId: string;
  }>({
    board_view: '',
    taskId: '',
  });
  const user = useUserContext();

  useEffect(() => {
    if (user?.uuid && user?.isMember) {
      try {
        if (!pusherRef.current) {
          console.log('PushProvider INITIALIZING');
          pusherRef.current = new Pusher(PUSH_KEY, {
            cluster: 'eu',
          });
        }
      } catch (error) {
        console.error('PUSHER ERROR', error);
      }

      if (pusherRef.current) {
        console.log('PushProvider SUBSCRIBING');
        pusherChannelRef.current = pusherRef.current.subscribe(user.uuid);

        console.log('Pusher binding to toast');
        pusherChannelRef.current.bind('toast', async function ({ message, taskId, type }: any) {
          console.log('PUSH INCOMING toast', { message, taskId, type });
          if (message) {
            if (type === 'error') {
              toast.error(message, { autoClose: 10000 });
            } else {
              toast.success(message);
            }
          }
        });

        console.log('Pusher binding to increaseNotification');
        pusherChannelRef.current.bind('increaseNotification', async function ({ taskId }: any) {
          // console.log('PUSH INCOMING increaseNotification', { taskId });
          setNotification((prev) => prev + 1);
        });

        console.log('Pusher binding to closeToast');
        pusherChannelRef.current.bind('closeToast', async function ({ taskId }: any) {
          // console.log('PUSH INCOMING closeToast', { taskId });
          toast.dismiss(taskId);
        });

        console.log('Pusher binding to passData');
        pusherChannelRef.current.bind('passData', async function ({ taskId, data }: any) {
          console.log('PUSH INCOMING passData', { taskId, data });
          setInternalState(data);
        });

        console.log('Pusher binding to closeDrawer');
        pusherChannelRef.current.bind('closeDrawer', async function ({ taskId }: any) {
          // console.log('PUSH INCOMING closeDrawer', { taskId });
          setIsDrawerOpen(false);
        });

        console.log('Pusher binding to refetch');
        pusherChannelRef.current.bind('refetch', async function ({ query, variables, taskId }: any) {
          console.log('PUSH INCOMING refetch', { query, variables, taskId });
          switch (query) {
            case 'QUERY_JOBS': {
              console.log('PUSH INCOMING refetch QUERY_JOBS');
              setRefetchQueries({
                board_view: 'QUERY_JOBS',
                taskId,
              });
              break;
            }
            case 'QUERY_JOB': {
              console.log('PUSH INCOMING refetch QUERY_JOB');
              setRefetchQueries({
                board_view: 'QUERY_JOB',
                taskId,
              });
              break;
            }
            case 'QUERY_JOB_POSTS': {
              console.log('PUSH INCOMING refetch QUERY_JOB_POSTS');
              setRefetchQueries({
                board_view: 'QUERY_JOB_POSTS',
                taskId,
              });
              break;
            }
            case 'QUERY_GET_BOARD': {
              console.log('PUSH INCOMING refetch QUERY_GET_BOARD');
              setRefetchQueries({
                board_view: 'QUERY_GET_BOARD',
                taskId,
              });
              break;
            }
          }
        });
      }
    } else {
      console.log('PushProvider NOT Enabled - destroying');
      pusherChannelRef.current?.unbind_all();
      pusherRef.current?.disconnect();
    }

    return () => {
      console.log('PushProvider UNMOUNTING');
      if (pusherRef.current) {
        console.log('PushProvider DISCONNECTING');
        if (user) {
          pusherChannelRef.current?.unbind_all();
          pusherRef.current.unsubscribe(user.uuid);
        }
        pusherRef.current.disconnect();
      }
    };
  }, [user]);

  function clearPushState() {
    setInternalState(null);
  }

  function clearRefetchQueries() {
    setRefetchQueries({
      board_view: '',
      taskId: '',
    });
  }

  return (
    <pushContext.Provider
      value={{
        notification,
        setNotification,
        refetchQueries,
        pushState,
        isDrawerOpen,
        clearPushState,
        clearRefetchQueries,
      }}
    >
      {children}
    </pushContext.Provider>
  );
};

export const usePushContext = () => {
  return useContext(pushContext);
};
