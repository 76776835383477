import { compile } from 'path-to-regexp';
import qs from 'qs';
import { Urls } from '../routes';

/**
 * Replace params in 'url' with with the values passed via 'params'.
 *
 * ie:
 *
 * parseUrl('/user/:userUuid/:applicationId', {
 *    userUuid: '1000',
 *    applicationId: '2000',
 * }, {
 *    destination: 'AUS'
 * })
 *
 * => '/user/1000/2000?destination=AUS'
 */
export function formatUrl(url: string, params?: { [key: string]: string }, query?: any) {
  let output = compile(url)(params);
  output += query && Object.keys(query).length ? `?${qs.stringify(query)}` : '';
  return output;
}

// google-oauth2 is the connection
export function formatGoogleLogin(state: string, redirect: string, connection: string = 'google-oauth2') {
  // https://targoo.eu.auth0.com/authorize?state=BJBI5PLD3lGK0350&client_id=bEts9CiqryZwcJY3omUV67ReThtUsv2a&response_type=token&connection=google-oauth2&prompt=login&scope=email%20openid%20profile&redirect_uri=http://localhost:5173/login?redirect=
  // console.log(
  //   'formatGoogleLogin',
  //   `${import.meta.env.VITE_APP_AUTH0_DOMAIN}/authorize?state=${state}&client_id=${
  //     import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  //   }&response_type=token&connection=${connection}&prompt=login&scope=email%20openid%20profile&redirect_uri=${
  //     import.meta.env.VITE_APP_URL
  //   }/${Urls.Login}?redirect=${redirect}`
  // );
  return `${import.meta.env.VITE_APP_AUTH0_DOMAIN}/authorize?state=${state}&client_id=${
    import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  }&response_type=token&connection=${connection}&prompt=login&scope=email%20openid%20profile&redirect_uri=${
    import.meta.env.VITE_APP_URL
  }/${Urls.Login}?redirect=${redirect}`;
}

// linkedin is not working for now as asking the wrong scope
// TempLinkedin not working as well - invalid user_id
// Fixed
export function formatLinkedin(state: string, redirect: string, connection: string = 'linkedin') {
  // response_type = token
  // connection = linkedin
  // scope =  email+openid+profile+r_liteprofile  // A space-delimited list of permissions that the application requires.
  return `${import.meta.env.VITE_APP_AUTH0_DOMAIN}/authorize?state=${state}&client_id=${
    import.meta.env.VITE_APP_AUTH0_CLIENT_ID
  }&response_type=token&connection=${connection}&prompt=login&scope=email%20r_emailaddress%20openid%20profile%20r_liteprofile&redirect_uri=${
    import.meta.env.VITE_APP_URL
  }/${Urls.Login}?redirect=${redirect}`;
}
