import { Youtube } from '@styled-icons/remix-line';
import { Youtube as YoutubeFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function YoutubeIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Youtube />
    </Icon>
  );
}

export function YoutubeFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <YoutubeFill />
    </Icon>
  );
}
