import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useAuthContext } from '../Auth0Context';
import { useGetMe } from '../../graph/queries/me';
import { User } from '../../generated/types';

const userContext = createContext<Omit<User, '__typename'> | null>(null);

export const UserProvider = ({ children }: React.PropsWithChildren<{}>) => {
  // const [isLoading, setLoading] = useState(false);
  const { isAuthenticated, signOut } = useAuthContext();
  const [getUser, { loading, error, data }] = useGetMe();

  useEffect(() => {
    async function fetchUser() {
      // setLoading(true);
      await getUser();
      // setLoading(false);
    }

    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated, getUser]);

  const user = useMemo(() => {
    if (loading) {
      return null;
    } else if (error) {
      console.error(error);
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED') {
        signOut();
      }
      return null;
    } else {
      return data?.me || null;
    }
  }, [loading, error, data, signOut]);

  // console.log(`RENDER UserProvider isAuthenticated: ${isAuthenticated} user: ${user}`);
  return (
    <userContext.Provider value={user}>
      {/* {isLoading ? <GlobalLoader /> : children} */}
      {children}
      {/* <pre>{JSON.stringify(user)}</pre> */}
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(userContext);
};
