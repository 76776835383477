import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useUserContext } from '../../../contexts/UserContext';
import { useAuthContext } from '../../../contexts/Auth0Context/Auth0Context';
import { zIndex } from '../../../styles/zIndex';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { useRouter } from '../../../hooks/useRouter';
import { Urls } from '../../../routes';

import { PrimaryButton, SecondaryButton, TertiaryButton } from '../../atoms/Button/Buttons';
import { Logo } from '../../atoms/Logo/Logo';
import { UserMenu } from '../UserMenu/UserMenu';
import { WrapperProps } from './NavBar.types';
import { CrownIcon, HeartIcon, HomeIcon, NotificationIcon, PadlockIcon, PriceIcon, StarIcon } from '../../atoms/Icons';
import { Badge } from '../../atoms/Badge';
import { IconButton } from '../../atoms/Icons/Icon';
import { Drawer } from '@mui/material';
import { DashboardNotifications } from '../../../routes/Dashboard/DashboardNotifications';
import { usePushContext } from '../../../contexts/PushContext';
import { TinyLoader } from '../../atoms/Loader';
import { MenuItem, Menu } from '../../atoms/Menu';
import { FaqIcon } from '../../atoms/Icons/FaqIcon';

export const NavBar: React.FC<{}> = () => {
  const { isAuthenticated } = useAuthContext();
  const user = useUserContext();
  const { navigate } = useRouter();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const { notification, setNotification } = usePushContext();

  useEffect(() => {
    // add style to body to avoid scroll when drawer is open
    if (isDrawerOpen || isHamburgerOpen) {
      document.body.style.overflow = 'hidden';
      // scroll to top
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDrawerOpen, isHamburgerOpen]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  let child = (
    <>
      <PrimaryButton
        onClick={() => {
          navigate(`/${Urls.Login}`);
        }}
      >
        Login
      </PrimaryButton>
      <Hamburguer onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}>
        <HamburguerLine $open={isHamburgerOpen} />
        <HamburguerLine $open={isHamburgerOpen} />
        <HamburguerLine $open={isHamburgerOpen} />
      </Hamburguer>
    </>
  );

  if (isAuthenticated) {
    child = (
      <>
        <ErrorWrapper>
          <TinyLoader />
        </ErrorWrapper>
      </>
    );
  }

  if (isAuthenticated && user) {
    child = <UserMenu />;
  }

  return (
    <>
      <Header>
        <Wrapper>
          <LogoWrapper to={isAuthenticated ? Urls.Dashboard : Urls.Home}>
            <Logo></Logo>
          </LogoWrapper>

          {isAuthenticated && user ? (
            <>
              {!user?.isMember && (
                <UpgradeWrapper>
                  <TertiaryButton
                    onClick={() => {
                      const url = `/${Urls.Checkout}`;
                      navigate(url);
                    }}
                    iconRight={<CrownIcon color={Colors.White} size={1.2} />}
                  >
                    Upgrade
                  </TertiaryButton>
                </UpgradeWrapper>
              )}
              <NotificationWrapper>
                <Badge badgeContent={notification} color="primary">
                  <IconButton
                    onClick={() => {
                      setNotification(0);
                      setIsDrawerOpen(!isDrawerOpen);
                    }}
                  >
                    <NotificationIcon
                      color={Colors.White}
                      size={0.8}
                      whileHover={{
                        rotateZ: [0, -20, 20, -20, 20, -20, 20, 0],
                        transition: { duration: 0.5 },
                        scale: 1.2,
                      }}
                    />
                  </IconButton>
                </Badge>
                <Drawer open={isDrawerOpen} onClose={toggleDrawer(false)}>
                  <DrawerWrapper>
                    <DashboardNotifications userUuid={user?.uuid} isDrawer={true} />
                  </DrawerWrapper>
                </Drawer>
              </NotificationWrapper>
            </>
          ) : (
            <MainLinks>
              <MainLink>
                <Link to={`/${Urls.Tools}/${Urls.ResumeGenerator}`}>AI Resume Builder</Link>
              </MainLink>
              <MainLink>
                <Link to={`/${Urls.Tools}/${Urls.CoverLetterGenerator}`}>Cover Letter Generator</Link>
              </MainLink>
              <MainLink>
                <Link to={`/${Urls.Tools}/${Urls.JobTracker}`}>Job Application Tracker</Link>
              </MainLink>
              <MainLink>
                <Link to={`/${Urls.Static}/${Urls.Features}`}>Features</Link>
              </MainLink>
            </MainLinks>
          )}
          <UserWrapper>{child}</UserWrapper>
        </Wrapper>
      </Header>
      {isHamburgerOpen && (
        <NavigationMobileWrapper>
          <Menu>
            <MenuItem icon={<HomeIcon size={1.8} />} isSelected={location.pathname === `/`}>
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Home}`}
              >
                Home
              </Link>
            </MenuItem>
            <MenuItem
              icon={<StarIcon size={1.8} />}
              isSelected={location.pathname === `/${Urls.Static}/${Urls.Features}`}
            >
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Static}/${Urls.Features}`}
              >
                Features
              </Link>
            </MenuItem>
            <MenuItem icon={<FaqIcon size={1.8} />} isSelected={location.pathname === `/${Urls.Static}/${Urls.Faqs}`}>
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Static}/${Urls.Faqs}`}
              >
                Faqs
              </Link>
            </MenuItem>
            <MenuItem
              icon={<PriceIcon size={1.8} />}
              isSelected={location.pathname === `/${Urls.Static}/${Urls.Pricing}`}
            >
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Static}/${Urls.Pricing}`}
              >
                Pricing
              </Link>
            </MenuItem>
            <MenuItem
              icon={<HeartIcon size={1.8} />}
              isSelected={location.pathname === `/${Urls.Static}/${Urls.About}`}
            >
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Static}/${Urls.About}`}
              >
                About us
              </Link>
            </MenuItem>
            <MenuItem
              icon={<PadlockIcon size={1.8} />}
              isSelected={location.pathname === `/${Urls.Static}/${Urls.Security}`}
            >
              <Link
                onClick={() => {
                  setIsHamburgerOpen(false);
                }}
                to={`/${Urls.Static}/${Urls.Security}`}
              >
                Security
              </Link>
            </MenuItem>
          </Menu>
        </NavigationMobileWrapper>
      )}
      <SpacerWrapper />
    </>
  );
};

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index: ${zIndex.Header};
`;

const DrawerWrapper = styled.div`
  max-width: 250px;
`;

const Wrapper = styled.div<WrapperProps>`
  background-color: ${Colors.Primary};
  width: 100%;
  padding: 0px 0px 0px var(--right-left-global-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  gap: 16px;
  ${media.from_tablet`
    height: 60px;
  `}
`;

const LogoWrapper = styled(Link)`
  display: flex;
  margin: 12px 0;
  flex: 1 1 200px;
  height: 40px;
  transform: translateY(2px);
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 300px;
  align-self: stretch;
  ${media.phone`
    flex-basis: 100px;
  `}
`;

const NotificationWrapper = styled.div`
  flex-basis: 20px;
  flex: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
  gap: 32px;
`;

const UpgradeWrapper = styled.div`
  display: none;
  ${media.from_tablet`
    display: block;
    flex: 1
    justify-content: flex-end;
  `}
`;

const SpacerWrapper = styled.div`
  height: 40px;
  ${media.from_tablet`
    height: 60px;
  `}
`;

const ErrorWrapper = styled.div`
  color: ${Colors.White};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Hamburguer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  margin: 0 8px;

  ${media.from_tablet`
    display: none;
  `}
`;

const HamburguerLine = styled.div<{
  $open: boolean;
}>`
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: ${Colors.White};
  transform-origin: 1px;
  transition: all 0.3s linear;

  &:nth-child(1) {
    transform: ${({ $open }: { $open: boolean }) => ($open ? 'rotate(45deg)' : 'rotate(0)')};
    background-color: ${({ $open }: { $open: boolean }) => ($open ? Colors.Contrast : Colors.White)};
  }
  &:nth-child(2) {
    transform: ${({ $open }: { $open: boolean }) => ($open ? 'translateX(+500%)' : 'translateX(0)')};
    opacity: ${({ $open }: { $open: boolean }) => ($open ? 0 : 1)};
    background-color: ${({ $open }: { $open: boolean }) => ($open ? Colors.Contrast : Colors.White)};
  }
  &:nth-child(3) {
    transform: ${({ $open }: { $open: boolean }) => ($open ? 'rotate(-45deg)' : 'rotate(0)')};
    background-color: ${({ $open }: { $open: boolean }) => ($open ? Colors.Contrast : Colors.White)};
  }
`;

const NavigationMobileWrapper = styled.div`
  top: 0px;
  bottom: 0px;
  padding-block: 60px;
  width: 100%;
  position: absolute;
  background-color: ${Colors.White};
  z-index: 11;
  ${media.from_tablet`
    display: none;
  `}
`;

const MainLinks = styled.ul`
  display: none;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  list-style: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${media.from_laptop`
    display: flex;
  `}
  ${media.from_large_laptop`
    gap: 32px;
  `}
`;

const MainLink = styled.li`
  display: flex;

  a {
    color: ${Colors.Black};
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s;
    &:hover {
      color: ${Colors.GreyDarkest};
    }
    ${media.from_large_laptop`
      font-size: 1.2rem;
    `}
  }
`;
