import { LinkedinBox } from '@styled-icons/remix-line';
import { LinkedinBox as LinkedinBoxFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function LinkedinIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <LinkedinBox />
    </Icon>
  );
}

export function LinkedinFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <LinkedinBoxFill />
    </Icon>
  );
}
