import styled, { keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { darken, lighten, transparentize } from 'polished';

import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { BaseFontStyles } from '../../../styles/fonts';
import { useFormContext } from 'react-hook-form';

const progressLinearMovement = keyframes`
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
`;

export const Button = styled.button<{ $inline?: boolean; $busy?: boolean }>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1.2px;
  border-radius: 4px;
  color: ${Colors.Black};
  cursor: ${(props) => (props.$busy ? 'no-drop' : 'pointer')};
  width: ${(props) => (props.$inline ? 'auto' : '100%')};
`;

export const ButtonContent = styled.div<{ $isSmall: boolean; $size: 'tiny' | 'small' | 'medium' | 'large' }>`
  display: flex;
  flex: 1;
  transform: translateY(2px);

  span {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ $size }) =>
    $size === 'tiny' &&
    `
      padding: 1px;
      ${media.from_tablet`
        padding: 2px;
      `}
    `};

  ${({ $size }) =>
    $size === 'small' &&
    `
      padding: 4px;
      ${media.from_tablet`
        padding: 12px;
      `}
    `};

  ${({ $size }) =>
    $size === 'medium' &&
    `
      padding: 18px;
    `};

  ${({ $size }) =>
    $size === 'large' &&
    `
      padding: 24px;
    `};
`;

export const ButtonProgress = styled.div`
  height: 5px;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  animation: start 0.3s ease-in;
  width: 100%;
  background-color: ${Colors.Contrast};
`;

export const ButtonProgressBar = styled.div<{ $busy?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => (props.$busy ? lighten(0.3, Colors.Contrast) : Colors.Transparent)};
  transition: transform 0.2s linear;
  width: 100%;
  animation: ${progressLinearMovement} 2s ease-in-out 0s infinite;
`;

const BaseButton = ({
  iconLeft,
  iconRight,
  children,
  type = 'button',
  isDisabled = false,
  busy,
  inline,
  isSmall = false,
  size = 'medium',
  ...otherProps
}: ButtonProps) => {
  // If the button is use inside a form we want to disable it when the form is submitting
  const method = useFormContext();
  const isSubmitting = method?.control?._formState?.isSubmitting;

  return (
    <Button
      type={type}
      $busy={busy || isSubmitting}
      $inline={inline}
      disabled={isDisabled || isSubmitting}
      {...otherProps}
    >
      <ButtonContent $isSmall={isSmall} $size={size}>
        <IconLeft>{iconLeft}</IconLeft>
        <span>{children}</span>
        <IconRight>{iconRight}</IconRight>
      </ButtonContent>
      <ButtonProgress>
        <ButtonProgressBar $busy={busy} />
      </ButtonProgress>
    </Button>
  );
};

export const PrimaryButton = styled(BaseButton)<ButtonProps>`
  background-color: ${(props) => (props.busy ? Colors.PrimaryTransparent : Colors.Primary)};
  transition: all 0.5s;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${(props) => (props.busy ? lighten(0.1, Colors.Primary) : darken(0.2, Colors.Primary))};
    }

    &:active {
      background-color: ${(props) => (props.busy ? lighten(0.1, Colors.Primary) : darken(0.3, Colors.Primary))};
    }
  }
`;

export const SecondaryButton = styled(PrimaryButton)<ButtonProps>`
  background-color: transparent;
  border-top: 2px solid ${Colors.Contrast};
  border-left: 2px solid ${Colors.Contrast};
  border-right: 2px solid ${Colors.Contrast};

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${(props) =>
        props.busy ? transparentize(0.9, Colors.Secondary) : transparentize(0.8, Colors.Secondary)};
    }

    &:active {
      background-color: ${(props) =>
        props.busy ? transparentize(0.9, Colors.Secondary) : transparentize(0.6, Colors.Secondary)};
    }
  }
`;

export const TertiaryButton = styled(BaseButton)<TertiaryButtonProps>`
  width: auto;
  height: auto;
  letter-spacing: 1px;
  transition: all 0.1s;
`;

export const ButtonMailto = ({ mailto, label }: { mailto: string; label: string }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export const LinkButton = styled.button`
  ${BaseFontStyles}
  padding: 0;
  border: none;
  border-bottom: 2px solid ${Colors.Contrast};
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  outline: 0;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

export const Link = styled(RouterLink)`
  ${BaseFontStyles}
  text-decoration: none;
  border-bottom: 2px solid ${Colors.Contrast};
  font-size: 14px;
  font-weight: 700;
`;

const LinkButtonWrapper = styled(RouterLink)`
  text-decoration: none;
`;

const IconLeft = styled.div`
  margin-right: 8px;
  /* transform: translateY(1px); */
`;

const IconRight = styled.div`
  margin-left: 8px;
  /* transform: translateY(3px); */
  display: flex;
  align-items: center;
`;

export const PrimaryButtonLink = ({ to, children, inline, ...otherProps }: LinkButtonProps) => (
  <LinkButtonWrapper to={to} style={{ display: inline ? 'inline-block' : 'block' }}>
    <PrimaryButton inline={inline} {...otherProps}>
      {children}
    </PrimaryButton>
  </LinkButtonWrapper>
);

export const SecondaryButtonLink = ({ to, children, inline, ...otherProps }: LinkButtonProps) => (
  <LinkButtonWrapper to={to} style={{ display: inline ? 'inline-block' : 'block' }}>
    <SecondaryButton inline={inline} {...otherProps}>
      {children}
    </SecondaryButton>
  </LinkButtonWrapper>
);

export const TertiaryButtonLink = ({ to, children, inline, ...otherProps }: LinkButtonProps & TertiaryButtonProps) => (
  <LinkButtonWrapper to={to} style={{ display: inline ? 'inline-block' : 'block' }}>
    <TertiaryButton inline={inline} {...otherProps}>
      {children}
    </TertiaryButton>
  </LinkButtonWrapper>
);

export const WrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // don't add paddle  here of it will break other stuff
  /* padding-block-end: 64px; */
  max-width: 900px;
  gap: 16px;
  /* border: 1px solid red; */
`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  inline?: boolean;
  busy?: boolean;
  children: any;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  isSmall?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large';
}

interface TertiaryButtonProps extends ButtonProps {
  $underline?: boolean;
}

interface LinkButtonProps extends ButtonProps {
  to: string;
  children: any;
}
