// https://codesandbox.io/s/framer-motion-5-3-scroll-triggered-animations-2hbg5?from-embed
import { useInView } from 'framer-motion';

import styled, { css } from 'styled-components';

import { Colors } from '../../../styles/colors';
import { useRef } from 'react';

export const BulletList = styled.ul<{ $hasBullets?: boolean; $bulletColor?: string }>`
  list-style: none;
  padding: 0;
  li {
    display: flex;
    width: 100%;
    position: relative;
    gap: 8px;

    ${({ $hasBullets = true, $bulletColor = Colors.Primary }) => {
      if ($hasBullets) {
        return css`
          padding-inline-start: 25px;
          &:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${$bulletColor};
            position: absolute;
            left: 8px;
            top: 8px;
          }
        `;
      }
    }};
  }
  li + li {
    margin-top: 4px;
  }
`;

export function BulletListItem({ children }: { children: React.ReactNode }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <li
      style={{
        transform: isInView ? 'none' : 'translateY(-30px)',
        opacity: isInView ? 1 : 0,
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      }}
      ref={ref}
    >
      {children}
    </li>
  );
}
