import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { MutationPasswordlessSignInArgs } from '../../generated/types';

export const PASSWORDLESS_SIGNIN = gql`
  mutation PasswordlessSignIn($state: String!, $email: String!, $redirectUri: String!) {
    passwordlessSignIn(state: $state, email: $email, redirectUri: $redirectUri)
  }
`;

export const usePasswordlessSignIn = (options?: MutationHookOptions<boolean, MutationPasswordlessSignInArgs>) =>
  useMutation<boolean, MutationPasswordlessSignInArgs>(PASSWORDLESS_SIGNIN, options);
