import { ChevronDown } from '@styled-icons/boxicons-solid';
import { Icon, IconProps } from './Icon';

export function ChevronDownIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ChevronDown />
    </Icon>
  );
}
