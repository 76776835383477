import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Routes } from './routes';
import { client } from './graph/client';
import { GlobalStyle } from './styles/GlobalStyle';
import { AuthProvider } from './contexts/Auth0Context';
import { ThemeProvider } from './contexts/ThemeContext';
import { UserProvider } from './contexts/UserContext';
import { SelectionProvider } from './contexts/SelectionContext';

import { ErrorBoundary } from './components/molecules/ErrorBoundary';
import { FeatureFlagsProvider } from './contexts/FeatureFlagsContext';
import { PushProvider } from './contexts/PushContext';

const environment = import.meta.env.VITE_APP_ENV || 'prod';
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  environment,
  dsn: VITE_SENTRY_DSN,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer style={{ fontSize: '14px', zIndex: 1000 }} />
      <StyleSheetManager>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <AuthProvider>
              <FeatureFlagsProvider>
                <ErrorBoundary message="UserProvider">
                  <UserProvider>
                    <ErrorBoundary message="PushProvider">
                      <PushProvider>
                        <ErrorBoundary message="SelectionProvider">
                          <SelectionProvider>
                            <ErrorBoundary message="ThemeProvider">
                              <ThemeProvider>
                                <Helmet defaultTitle="JobsBoard" titleTemplate="JobsBoard - %s" />
                                <ErrorBoundary message="Routes">
                                  <Routes />
                                </ErrorBoundary>
                              </ThemeProvider>
                            </ErrorBoundary>
                          </SelectionProvider>
                        </ErrorBoundary>
                      </PushProvider>
                    </ErrorBoundary>
                  </UserProvider>
                </ErrorBoundary>
              </FeatureFlagsProvider>
            </AuthProvider>
          </BrowserRouter>
        </ApolloProvider>
      </StyleSheetManager>
    </>
  );
};
