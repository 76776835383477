import { jwtDecode } from 'jwt-decode';

export function decode(token: string): unknown | undefined {
  // this is just for decoding the token. Not for verifying it.
  // Any verification should be done on the server side.
  let decodedToken;
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error(error);
  }
  return decodedToken;
}
