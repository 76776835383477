// https://styled-icons.dev/
// https://remixicon.com/
import styled, { css } from 'styled-components';
import { IconButton as MuyIconButton } from '@mui/material';
import { motion, HTMLMotionProps } from 'framer-motion';

import { Colors } from '../../../styles/colors';

export const Icon = styled(motion.div)<IconProps>`
  display: flex;

  ${({ size = 1 }) => {
    if (size) {
      return css`
        width: ${size}em;
      `;
    }
  }};

  > svg {
    flex: 1;
    height: auto;
    ${({ color = Colors.Primary }) => {
      if (color) {
        return css`
          color: ${color};
        `;
      }
    }};
  }
`;
export interface IconProps extends HTMLMotionProps<'div'> {
  onClick?: any;
  ref?: any;
  size?: number;
  color?: Colors;
  animate?: {
    scale: number;
  };
  transition?: {
    duration: number;
  };
  whileHover?: any;
}

export const IconButton = styled(MuyIconButton)`
  padding: 0;
  margin: 0;
  border: 1px solid red;
  background-color: ${Colors.Primary};
`;
