import gql from 'graphql-tag';
import { useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query } from '../../generated/types';

export const GET_ME = gql`
  query me {
    me {
      uuid
      nickname
      name
      phone
      avatar
      email
      dateOfBirth
      enableEmailNotification
      enableEmailDailySummaryEmail
      enableEmailWeeklySummaryEmail
      enablePushNotification
      enableSmsNotification
      enableMarketingEmail
      hasGoogleAuth
      hasGoogleAuthAt
      hasLinkedinAuth
      hasLinkedinAuthAt
      hadTrial
      state
      createdAt
      updatedAt
      isDeleted
      isEmailVerified
      isMember
      isAdmin
      membershipPlan
      permissions
      ipV4
      credits
      extraCredits
      creditsHistory
    }
  }
`;

export const useGetMe = (options?: QueryHookOptions<Pick<Query, 'me'>, {}>) =>
  useLazyQuery<Pick<Query, 'me'>, {}>(GET_ME, options);
