import React, { CSSProperties, useEffect, useState } from 'react';

export const VisuallyHidden = ({ children }: VisuallyHiddenProps) => {
  const [forceShow, setForceShow] = useState<boolean>(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const handleKeyDown = (ev: any) => {
        if (ev?.key === 'Alt') {
          setForceShow(true);
        }
      };
      const handleKeyUp = (ev: any) => {
        if (ev?.key === 'Alt') {
          setForceShow(false);
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keydown', handleKeyUp);
      };
    }
  }, []);

  if (forceShow) {
    return <>{children}</>;
  }
  return <span style={hiddenStyles}>{children}</span>;
};

const hiddenStyles: CSSProperties = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

type VisuallyHiddenProps = {
  children: React.ReactNode;
};
