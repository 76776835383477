import { Crown } from '@styled-icons/fa-solid';

import { Icon, IconProps } from './Icon';

export function CrownIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Crown />
    </Icon>
  );
}
