import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import { Icon, IconProps } from './Icon';

export function SearchIcon({ ref, ...props }: IconProps) {
  return (
    <Icon {...props}>
      <MagnifyingGlassIcon ref={ref} />
    </Icon>
  );
}
