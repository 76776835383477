import { PriceTag2 } from '@styled-icons/remix-line';
import { PriceTag2 as PriceTag2Fill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function PriceIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <PriceTag2 />
    </Icon>
  );
}

export function PriceFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <PriceTag2Fill />
    </Icon>
  );
}
