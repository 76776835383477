import { ErrorBoundary as ReactErrorBoundary, FallbackProps, useErrorBoundary } from 'react-error-boundary';
import { PrimaryButton } from '../../atoms/Button';
import { VisuallyHidden } from '../../atoms/VisuallyHidden';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { useLocalStorage } from '../../../hooks/useLocalStorage';

import bugImage from '../../../assets/vectors/bug.svg';

import { Colors } from '../../../styles/colors';
import { LayoutCentered } from '../../atoms/Layout/Layout';
import { Placeholder } from '../../atoms/Placeholder';
import { Size } from '../../atoms/Placeholder/Placeholder';
import { useEffect } from 'react';

export const ErrorBoundary = ({ children, message }: { message?: string; children: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary fallback={(error) => <ErrorFallbackSentry error={error} message={message} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const logError = (error: Error, info: { componentStack: string }, message: string) => {
  console.log(message);
  console.error(error);
  console.info(info);
};

const ErrorFallback = (fallback: FallbackProps, message?: string) => {
  const { resetBoundary } = useErrorBoundary();
  console.log(fallback);
  console.log(message);
  console.log(fallback?.error);
  console.log(fallback?.error?.message);

  return (
    <Wrapper role="alert">
      <ErrorMessages>Something went wrong:</ErrorMessages>
      <VisuallyHidden>
        <pre style={{ color: 'green' }}>{message}</pre>
      </VisuallyHidden>
      <VisuallyHidden>
        <pre style={{ color: 'red' }}>{fallback.error.message}</pre>
      </VisuallyHidden>

      <PrimaryButton
        onClick={() => {
          // reload the page
          window.location.reload();
        }}
        inline={true}
      >
        Try again
      </PrimaryButton>
    </Wrapper>
  );
};

const ErrorFallbackSentry = (error?: any, message: string = '') => {
  const [storedErrorCount, setStoredErrorCount] = useLocalStorage('JOBPOD_ERROR_COUNT', '0');
  console.error(message);
  console.error(error?.error?.error?.message);

  const errors = ['Failed to fetch dynamically imported module', 'error loading dynamically imported module'];
  const hasLoadingError = errors.some((errorString) => error?.error?.error?.message?.includes(errorString));

  const refreshPage = () => {
    window.location.reload();
    const storedErrorCountNumber = parseInt(storedErrorCount, 10);
    setStoredErrorCount(storedErrorCountNumber + 1);
  };

  useEffect(() => {
    if (hasLoadingError) {
      refreshPage();
    }
  }, []);

  return (
    <Wrapper role="alert">
      <ErrorMessages></ErrorMessages>

      <LayoutCentered>
        <Placeholder
          icon={bugImage}
          iconAlt="Error"
          iconSize={Size.MEDIUM}
          title="Something went wrong"
          description={
            <>
              <VisuallyHidden>
                <pre style={{ color: 'green' }}>{JSON.stringify({ error }, null, 2)}</pre>
              </VisuallyHidden>

              <Centered>
                <PrimaryButton onClick={refreshPage} inline={true}>
                  Try again
                </PrimaryButton>
              </Centered>
            </>
          }
        />
      </LayoutCentered>
    </Wrapper>
  );
};

const ErrorMessages = styled.div`
  display: flex;
  padding: 8px;
`;

const Wrapper = styled.div`
  border: 1px dotted ${Colors.Grey};
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
