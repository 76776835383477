import { Settings } from '@styled-icons/feather';
import { Icon, IconProps } from './Icon';

export function SettingsIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Settings />
    </Icon>
  );
}
