import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryNotificationsArgs } from '../../generated/types';

export const GET_NOTIFICATIONS = gql`
  query Notifications($where: NotificationsInputWhere!, $sort: InputSort!, $limit: Int) {
    notifications(where: $where, sort: $sort, limit: $limit) {
      uuid
      description
      data
      createdAt
    }
  }
`;

export const useGetNotifications = (options?: QueryHookOptions<Pick<Query, 'notifications'>, QueryNotificationsArgs>) =>
  useQuery<Pick<Query, 'notifications'>, QueryNotificationsArgs>(GET_NOTIFICATIONS, options);

export const useLazyGetNotifications = (
  options?: QueryHookOptions<Pick<Query, 'notifications'>, QueryNotificationsArgs>
) => useLazyQuery<Pick<Query, 'notifications'>, QueryNotificationsArgs>(GET_NOTIFICATIONS, options);
