// https://mui.com/material-ui/react-badge/
import MuiBadge from '@mui/material/Badge';
import { BadgeProps } from './Badge.types';

import { Container } from './Badge.styles';

export const Badge: React.FC<any> = ({
  children,
  color = 'primary',
  variant = 'standard',
  badgeContent,
  max = 99,
  onActionClick,
}: React.PropsWithChildren<BadgeProps>) => {
  return (
    <Container>
      <MuiBadge badgeContent={badgeContent} color={color} variant={variant} max={max}>
        <div onClick={onActionClick}>{children}</div>
      </MuiBadge>
    </Container>
  );
};
