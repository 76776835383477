export const Urls = {
  Home: '/',
  Login: 'login',

  Checkout: 'checkout',
  CheckoutConfirm: 'confirm',

  Dashboard: 'dashboard',

  Admin: 'admin',
  AdminJobPosts: 'jobposts',
  AdminUsers: 'users',

  Boards: 'boards',
  BoardUpdate: 'user/:userUuid/board-update/:boardUuid',
  BoardView: 'user/:userUuid/board/:boardUuid',

  Jobs: 'jobs',
  JobView: 'user/:userUuid/board/:boardUuid/job/:jobUuid',

  JobPosts: 'jobPosts',
  JobPostView: 'user/:userUuid/job/:jobUuid',

  JobUpdateHome: '/jobflow/update',
  JobUpdateStep1: 'user/:userUuid/board/:boardUuid/job/:jobUuid/step/1',
  JobUpdateStep2: 'user/:userUuid/board/:boardUuid/job/:jobUuid/step/2',
  JobUpdateStep3: 'user/:userUuid/board/:boardUuid/job/:jobUuid/step/3',
  JobUpdateStep4: 'user/:userUuid/board/:boardUuid/job/:jobUuid/step/4',
  JobUpdateStep5: 'user/:userUuid/board/:boardUuid/job/:jobUuid/step/5',

  JobOfferUpdateHome: '/jobpostflow/update',
  JobOfferUpdateStep1: 'user/:userUuid/job/:jobUuid/step/1',
  JobOfferUpdateStep2: 'user/:userUuid/job/:jobUuid/step/2',
  JobOfferUpdateStep3: 'user/:userUuid/job/:jobUuid/step/3',
  JobOfferUpdateStep4: 'user/:userUuid/job/:jobUuid/step/4',
  JobOfferUpdateStep5: 'user/:userUuid/job/:jobUuid/step/5',

  ResumeUpdateHome: '/resumeflow/update',
  BoardResumeUpdateStepGeneric: 'user/:userUuid/board/:boardUuid/resume/:resumeUuid/step/:stepId',
  JobResumeUpdateStepGeneric: 'user/:userUuid/board/:boardUuid/job/:jobUuid/resume/:resumeUuid/step/:stepId',

  Static: 'static',
  TermsOfUse: 'terms-of-use',
  PrivacyPolicy: 'privacy-policy',
  Features: 'features',
  Faqs: 'fags',
  Pricing: 'pricing',
  AuthError: 'auth-error',
  About: 'about',
  Security: 'security',
  Contact: 'contact',

  Tools: 'tools',
  JobTracker: 'job-tracker',
  CoverLetterGenerator: 'cover-letter-generator',
  ResumeGenerator: 'resume-generator',

  Public: 'public',
  PublicBoardView: 'board/user/:userUuid/board/:boardUuid',
  PublicJobView: 'job/user/:userUuid/board/:boardUuid/job/:jobUuid',
  PublicBoardResumeView: 'resume/user/:userUuid/board/:boardUuid/resume/:resumeUuid',
  PublicJobResumeView: 'resume/user/:userUuid/board/:boardUuid/job/:jobUuid/resume/:resumeUuid',

  Profile: '/profile',
  SignUp: '/signup',
  ResetPassword: '/reset-password',
  VerifyLogin: '/verify-login',
  VerifyEmail: '/verify-email',
  VerifyPhone: '/verify-phone',
  SecureYourAccount: '/secure-your-account',
  RequireNewPassword: '/new-password',
  Logout: '/logout',

  Settings: 'settings',
};

export const UrlsBack = {
  [Urls.JobView]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobUpdateStep1]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobUpdateStep2]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobUpdateStep3]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobUpdateStep4]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobUpdateStep5]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobCreateStep1]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobCreateStep2]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobCreateStep3]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobCreateStep4]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.JobCreateStep5]: {
    url: Urls.BoardView,
    name: 'Jobs Board',
  },
  [Urls.BoardView]: {
    url: Urls.Dashboard,
    name: 'Dashboard',
  },
  [Urls.BoardUpdate]: {
    url: Urls.Dashboard,
    name: 'Dashboard',
  },
  [Urls.Home]: {
    url: Urls.Dashboard,
    name: 'Dashboard',
  },
};
