export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDate = ({ date, format = 'short', locale = 'en-UK' }: formatDateOptions) => {
  if (date === null) return '';
  const dateObject = new Date(date);
  if (!isValidDate(dateObject)) return date;

  try {
    switch (format) {
      case 'shortdatetime':
        return dateObject.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });

      case 'longdatetime':
        return dateObject.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });

      case 'time':
        return dateObject.toLocaleTimeString(locale, {
          hour: 'numeric',
          minute: 'numeric',
        });
      case 'long':
        return dateObject.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
      case 'short':
        return dateObject.toLocaleDateString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' });
      default:
        return date;
    }
  } catch (error) {
    return date;
  }
};

function isValidDate(date: any): boolean {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return false;
    }
    return true;
  }
  return false;
}

type formatDateOptions = {
  date: string;
  format?: 'long' | 'short' | 'longdatetime' | 'shortdatetime' | 'time';
  locale?: string;
};
