import { Star } from '@styled-icons/remix-line';
import { Star as StarFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function StarIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Star />
    </Icon>
  );
}

export function StarFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <StarFill />
    </Icon>
  );
}
