import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Urls } from '../../../routes';
import { Colors } from '../../../styles/colors';

import { useUserContext } from '../../../contexts/UserContext';

import {
  LinkedinFullIcon,
  LawIcon,
  CoffeeIcon,
  TwitterIcon,
  FacebookFullIcon,
  YoutubeFullIcon,
} from '../../atoms/Icons';
import { BulletList } from '../../atoms/BulletList';
import { Link } from '../../atoms/Button';
import { Logo, SimpleLogo } from '../../atoms/Logo/Logo';
import { ButtonMailto } from '../../atoms/Button/Buttons';
import { HeadingStyle1 } from '../../../styles/fonts';

export const Footer: React.FC<{}> = () => {
  const user = useUserContext();
  const isAdmin = user?.isAdmin || false;
  const isMember = user?.isMember || false;

  return (
    <FooterWrapper>
      <Columns>
        <WideColumn>
          <LogoContainer>
            <Logo></Logo>
          </LogoContainer>
          <CompanyDescription>The Smart Dashboard for your Job Search</CompanyDescription>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.linkedin.com/company/jobsboard-io/">
              <LinkedinFullIcon size={2} color={Colors.White} />
            </SocialLink>
            {/* <SocialLink href="https://instagram.com">
              <TwitterIcon size={2} color={Colors.White} />
            </SocialLink> */}
            <SocialLink target="_blank" href="https://www.youtube.com/channel/UCsT1JeCz9huuvRaBshajNyQ">
              <YoutubeFullIcon size={2} color={Colors.White} />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.facebook.com/jobsboard.io">
              <FacebookFullIcon size={2} color={Colors.White} />
            </SocialLink>
            {/* <SocialLink target="_blank" href="https://www.buymeacoffee.com/jobsboard">
              <CoffeeIcon size={2} color={Colors.White} />
            </SocialLink> */}
          </SocialLinksContainer>
        </WideColumn>
        <LinksColumn>
          <Column>
            <ColumnHeading>Tools</ColumnHeading>
            <BulletList $hasBullets={false}>
              <li>
                <Link to={`/${Urls.Tools}/${Urls.JobTracker}`}>Job Tracker</Link>
              </li>
              <li>
                <Link to={`/${Urls.Tools}/${Urls.ResumeGenerator}`}>Resume Generator</Link>
              </li>
              <li>
                <Link to={`/${Urls.Tools}/${Urls.CoverLetterGenerator}`}>Cover Letter Generator</Link>
              </li>
            </BulletList>
          </Column>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <BulletList $hasBullets={false}>
              <li>
                <Link to={Urls.Home}>Home</Link>
              </li>
              <li>
                <Link to={`/${Urls.Login}`}>Login</Link>
              </li>
              <li>
                <Link to={`/${Urls.Static}/${Urls.About}`}>About us</Link>
              </li>
              <li>
                <Link to={`/${Urls.Static}/${Urls.Security}`}>Security</Link>
              </li>
              {isAdmin && (
                <li>
                  <Link to={`/${Urls.Admin}/${Urls.AdminJobPosts}`}>Admin</Link>
                </li>
              )}
              <li>
                <ButtonMailto mailto="mailto:support@jobsboard.io" label="Contact Us" />
              </li>
            </BulletList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <BulletList $hasBullets={false}>
              {(isMember || isAdmin) && (
                <li>
                  <Link to={`/${Urls.JobPosts}`}>Job Posts</Link>
                </li>
              )}
              <li>
                <Link to={`/static/${Urls.Features}`}>Features</Link>
              </li>
              <li>
                <Link to={`/static/${Urls.Faqs}`}>Faqs</Link>
              </li>
              <li>
                <Link to={`/static/${Urls.Pricing}`}>Pricing</Link>
              </li>
            </BulletList>
          </Column>
          <DesktopColumn>
            <SimpleLogoWrapper>
              <SimpleLogo></SimpleLogo>
            </SimpleLogoWrapper>
          </DesktopColumn>
        </LinksColumn>
      </Columns>
      <BottomSection>
        <BottomSectionLeft>
          <div>© {new Date().getFullYear()} JobsBoard.io All rights reserved.</div>
          <div>Built with ❤️ in London UK.</div>
        </BottomSectionLeft>
        <BottomSectionRight>
          <LineWrapper>
            <LawIcon size={1} color={Colors.White} />
            <Link to={`/${Urls.Static}/${Urls.TermsOfUse}`}>JobsBoard&rsquo;s Terms of Use</Link>
          </LineWrapper>
          <LineWrapper>
            <LawIcon size={1} color={Colors.White} />
            <Link to={`/${Urls.Static}/${Urls.PrivacyPolicy}`}>Privacy Policy</Link>
          </LineWrapper>
        </BottomSectionRight>
      </BottomSection>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.section`
  min-height: 200px;
  background-color: ${Colors.Primary};
  padding: 32px var(--right-left-global-padding);
`;

const Columns = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
  gap: 32px;
`;

const WideColumn = styled.div`
  max-width: 400px;
`;

const LinksColumn = styled.div`
  display: flex;
  flex: 1 1 500px;
  justify-content: space-between;
`;

const Column = styled.div``;

const DesktopColumn = styled.div`
  display: none;
  ${media.from_large_laptop`
    display: block;
  `}
`;

const LogoContainer = styled.div`
  padding: 5px 0;
  width: 100%;

  ${media.from_laptop`
    width: min(300px, 100%);
  `}
`;

const CompanyDescription = styled.section`
  font-weight: bold;
`;

const SocialLinksContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 0;
  ${media.from_tablet`
    justify-content: flex-start;
  `}
`;

const SocialLink = styled.a``;

const ColumnHeading = styled.section`
  ${HeadingStyle1}
  font-size: 2rem;
  margin-bottom: 16px;
`;

const LineWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SimpleLogoWrapper = styled.div`
  display: none;
  ${media.from_tablet`
    display: block;
  `}
`;

const BottomSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  ${media.from_tablet`
    flex-direction: row;
  `}
`;

const BottomSectionLeft = styled.div`
  order: 2;
  justify-content: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  ${media.from_tablet`
    justify-content: flex-start;
    order: 1;
  `}
`;

const BottomSectionRight = styled.div`
  order: 1;
  justify-content: space-between;
  gap: 0 16px;
  display: flex;
  flex: 1;
  ${media.from_tablet`
    justify-content: flex-end;
    order: 2;
  `}
`;
