export enum Colors {
  Transparent = 'transparent',
  PrimaryTransparent = 'hsl(120, 15%, 85%, 0.1)',
  PrimaryUltraLightest = 'hsl(120, 15%, 95%)', // #D3DED3
  PrimaryLightest = 'hsl(120, 15%, 85%)', // #D3DED3
  PrimaryLighter = 'hsl(120, 15%, 80%)', // #C4D4C4
  PrimaryLight = 'hsl(120, 15%, 75%)', // #B6C9B6
  Primary = 'hsl(120, 15%, 70%)', // #A7BDA7
  PrimaryDark = 'hsl(120, 15%, 65%)', // #98B398
  PrimaryDarker = 'hsl(120, 15%, 60%)', // #8AA88A
  PrimaryDarkest = 'hsl(120, 15%, 55%)', // #778c77

  SecondaryLight = 'hsl(182, 74%, 65%)',
  Secondary = 'hsl(182, 74%, 60%)', // #4edfe4
  SecondaryDark = 'hsl(182, 74%, 55%)',

  ContrastTransparent = 'hsl(300, 15%, 85%, 0.1)',
  ContrastOverlay = 'hsl(300, 15%, 85%, 0.7)',
  ContrastUltraLightest = 'hsl(300, 15%, 85%, 0.3)',
  ContrastLightest = 'hsl(300, 15%, 85%)', // #DED3DE
  ContrastLighter = 'hsl(300, 15%, 80%)', // #D4C4D4
  ContrastLight = 'hsl(300, 15%, 75%)', // #C9B6C9
  Contrast = 'hsl(300, 15%, 70%)', // #BEA7BE
  ContrastDark = 'hsl(300, 15%, 65%)', // #B398B3
  ContrastDarker = 'hsl(300, 15%, 60%)', // #A88AA8
  ContrastDarkest = 'hsl(300, 15%, 55%)', // #9D7B9D

  Black = 'hsl(0, 0%, 0%)',
  BlackLight = 'hsl(0, 0%, 10%)',
  BlackLighter = 'hsl(0, 0%, 20%)',
  BlackLightest = 'hsl(0, 0%, 30%)',

  White = 'hsl(0, 0%, 100%)',

  GreyDarkest = 'hsl(0, 0%, 35%)', // #595959
  GreyDarker = 'hsl(0, 0%, 40%)',
  GreyDark = 'hsl(0, 0%, 45%)',
  Grey = 'hsl(0, 0%, 50%)',
  GreyLight = 'hsl(0, 0%, 55%)',
  GreyLighter = 'hsl(0, 0%, 70%)',
  GreyLightest = 'hsl(0, 0%, 80%)', // #A6A6A6
  GreyTransparent = 'hsl(0, 0%, 90%)', // #A6A6A6

  ErrorLight = 'hsl(346, 84%, 70%)',
  Error = 'hsl(346, 84%, 60%)',
  ErrorDark = 'hsl(346, 84%, 40%)',

  WarningLight = 'hsl(42, 100%, 70%)',
  Warning = 'hsl(42, 100%, 60%)',
  WarningDark = 'hsl(42, 100%, 40%)',

  InfoLight = 'hsl(164, 95%, 70%)',
  Info = 'hsl(164, 95%, 60%)',
  InfoDark = 'hsl(164, 95%, 40%)',
}
