import { Loader } from '@styled-icons/remix-line';
import { Loader as LoaderFill } from '@styled-icons/remix-fill';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../../styles/colors';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export function SpinnerIcon() {
  return (
    <Icon>
      <Loader />
    </Icon>
  );
}

export function SpinnerFullIcon() {
  return (
    <Icon>
      <LoaderFill />
    </Icon>
  );
}

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.PrimaryDarkest};
  svg {
    width: 32px;
    height: 32px;
    animation: ${spinAnimation} 3s linear infinite; /* Add spinning animation */
  }
`;
