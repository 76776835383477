export const MediaSizes = {
  mobile: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const target = (from: number | null, to?: number | null) => (literals: TemplateStringsArray) => {
  const rules = [];
  from && rules.push(`(min-width: ${from}px)`);
  to && rules.push(`(max-width: ${to - 1}px)`);

  return `@media ${rules.join(' and ')} {
    ${literals}
  }`;
};

export const media = {
  target,
  from_mobile: target(MediaSizes.mobileL),
  from_tablet: target(MediaSizes.tablet),
  from_laptop: target(MediaSizes.laptop),
  from_large_laptop: target(MediaSizes.laptopL),
  tablet: target(null, MediaSizes.desktop),
  phone: target(null, MediaSizes.tablet),
  small: target(null, MediaSizes.mobile),
};
