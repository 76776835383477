import { createContext, useContext, useReducer } from 'react';

const initialState: State = {
  selectedBoardUuuid: null,
};

const selectionContext = createContext<Context | null>(null);

export const SelectionProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selection = {
    state,
    dispatch,
  };
  return (
    <>
      <selectionContext.Provider value={selection}>{children}</selectionContext.Provider>
      {/* <pre>{JSON.stringify(selection, null, 4)}</pre> */}
    </>
  );
};

export const useSelectionContext = () => {
  return useContext(selectionContext);
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.SELECT_BOARD:
      return {
        ...state,
        selectedBoardUuuid: action.payload,
      };

    case ActionType.CLEAR_ALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export enum ActionType {
  SELECT_BOARD = 'SELECT_BOARD',
  CLEAR_ALL = 'CLEAR_ALL',
}

type State = {
  selectedBoardUuuid: string | null;
};

type Action = {
  type: ActionType;
  payload: string;
};

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};
