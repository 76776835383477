import React, { useState, useEffect, useContext, createContext } from 'react';

import { decode } from '../../utils/jwt';
import { AuthContextValue, authUser } from './Auth0Context.type';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useRouter } from '../../hooks/useRouter';
import { usePasswordlessSignIn } from '../../graph/mutations/passwordlessSignIn';
import { useSignInConfirm } from '../../graph/mutations/signInConfirm';
import { useLogout } from '../../graph/mutations/logout';
import { GlobalLoader } from '../../components/atoms/GlobalLoader/GlobalLoader';

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [jwt, setJwt, deleteJwt] = useLocalStorage('JOBPOD_JWT', '');
  const [storedState] = useLocalStorage('JOBPOD_STATE', '');
  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authUser, setAuthUser] = useState<authUser | null>(null);
  const [signInConfirm] = useSignInConfirm();
  const [passwordlessSignIn] = usePasswordlessSignIn();
  const [logout] = useLogout();
  const {
    pathname,
    location: { hash },
  } = useRouter();

  // The following code is run on every page reload
  // It checks if the user is authenticated and updates the state accordingly
  // Could be from URL where we make an API call
  // Could be from JWT in local storage

  // If the user in JWT is good but query.me is not.. we should kill the cookie.

  // Only run on page reload,
  useEffect(() => {
    const initAuth0FromURL = async (accessToken: string, state: string) => {
      const { data } = await signInConfirm({
        variables: {
          accessToken,
          state,
        },
      });
      if (data?.signInConfirm) {
        setIsAuthenticated(true);
        setAuthUser(data.signInConfirm);
        setJwt(data.signInConfirm.jwt);
      }
    };

    const initAuth0FromJWT = () => {
      const auth0 = decode(jwt) as authUser;
      setIsAuthenticated(true);
      setAuthUser(auth0);
    };

    setLoading(true);

    try {
      // Update auth based on hash in URL on the login page
      const parsedHash = new URLSearchParams(hash.substring(1));
      const accessToken = parsedHash.get('access_token');
      const state = parsedHash.get('state');
      if (accessToken && state && pathname === '/login') {
        if (storedState !== state) {
          console.log('InitAuth0FromURL but storedState !== state', storedState, state);
        }
        initAuth0FromURL(`${accessToken}`, `${state}`);
        // Update auth based on JWT in the local storage
      } else if (jwt) {
        initAuth0FromJWT();
      }
    } catch (error) {
      deleteJwt();
      setIsAuthenticated(false);
      setAuthUser(null);
    }

    setLoading(false);
  }, []);

  const signOut = async () => {
    // https://www.apollographql.com/docs/react/networking/authentication/
    // client.resetStore()
    try {
      console.log('SignOut manually');
      await logout();
    } catch (error) {
      console.error(error);
    }
    deleteJwt();
    setIsAuthenticated(false);
    setAuthUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        authUser,
        passwordlessSignIn,
        signOut,
      }}
    >
      <>
        {isLoading ? <GlobalLoader /> : children}
        {/* <pre>
          {JSON.stringify(
            {
              isLoading,
              isAuthenticated,
              authUser,
              passwordlessSignIn,
              signOut,
            },
            null,
            4
          )}
        </pre> */}
      </>
    </AuthContext.Provider>
  );
};
