import { Heart } from '@styled-icons/fa-regular';
import { Heart as HeartSolid } from '@styled-icons/fa-solid';
import { Icon, IconProps } from './Icon';

export function HeartIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Heart />
    </Icon>
  );
}

export function HeartFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <HeartSolid />
    </Icon>
  );
}
