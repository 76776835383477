import { useEffect } from 'react';

// Minimum amount of time the loader can be visible before being hidden.
const MINIMIUM_DURATION = 1000;

let instances = 0;
let mountTime = Date.now();
let unmountTimeout: any;
const el = document.getElementById('global-loader');

const showEl = () => {
  if (el) {
    el.style.display = 'flex';
  }
};

const hideEl = () => {
  if (el) {
    el.style.display = 'none';
  }
};

/**
 * A component to control the visibility of the global-loader element.  Keeps track
 * of mounts so the element is hidden when there are no currently mounted instances
 * in the component tree.
 */
export const GlobalLoader = () => {
  useEffect(() => {
    instances++;
    clearTimeout(unmountTimeout);
    if (instances === 1) {
      showEl();
      mountTime = Date.now();
    }
    return () => {
      // When the Auth context is loaded, this component will be unmounted.
      instances--;
      if (instances === 0) {
        const visibleDuration = Date.now() - mountTime;
        const difference = MINIMIUM_DURATION - visibleDuration;
        if (difference > 0) {
          unmountTimeout = setTimeout(hideEl, difference);
        } else {
          hideEl();
        }
      }
    };
  });
  return null;
};
