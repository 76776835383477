import { useRef, useState, useEffect } from 'react';

interface useElementFocusOpts {
  initialHasFocus?: boolean;
  onFocus?: React.FocusEventHandler<any>;
  onBlur?: React.FocusEventHandler<any>;
}

/**
 * Tracks 'focus' and 'blur' events on an element, ignoring any child elements.
 * Solves the problem of 'onBlur' events firing by default for an element whenever
 * a child element receives focus.
 *
 * Returns two event handlers 'handleFocus' and 'handleBlur' which should be called
 * during the focus and blur events of the parent element.
 */
function useElementFocus<ElementType>(opts?: useElementFocusOpts) {
  let timeout = useRef<number | undefined>(undefined);
  const [hasFocus, setHasFocus] = useState(opts && opts.initialHasFocus);

  const reset = () => {
    clearTimeout(timeout.current);
    timeout.current = undefined;
  };

  useEffect(() => reset, []);

  return {
    hasFocus,
    handleFocus(e: React.FocusEvent<ElementType>) {
      e.persist();
      reset();
      if (!hasFocus && opts && opts.onFocus) {
        opts.onFocus(e);
      }
      setHasFocus(true);
    },
    handleBlur(e: React.FocusEvent<ElementType>) {
      e.persist();
      // @ts-ignore
      timeout.current = setTimeout(() => {
        if (!timeout) {
          return false;
        }
        if (hasFocus && opts && opts.onBlur) {
          opts.onBlur(e);
        }
        setHasFocus(false);
        reset();
      }, 0);
    },
  };
}

export default useElementFocus;
