import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';

const authLink = setContext(async (_, { headers }) => {
  let Authorization;
  try {
    Authorization = window.localStorage.getItem('JOBPOD_JWT');
  } catch (error) {
    console.error('error', error);
  }
  return {
    headers: {
      Authorization,
      ...headers,
    },
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const logoutCodes = ['UNAUTHENTICATED'];
  let errorCodes: string[] = [];
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions: { code } = {} }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`);
      errorCodes.push(code as string);
    });
  }
  if (errorCodes.some((code) => logoutCodes.includes(code))) {
    toast.error('Your session has expired. Please log in again.');
    window.localStorage.removeItem('JOBPOD_JWT');
    // let currentURL = window.location.href;
    // let newQueryString = 'reason=logout';
    // // Check if there's already a query string
    // if (currentURL.includes('?')) {
    //   // If there is, append the new parameter
    //   currentURL += `&${newQueryString}`;
    // } else {
    //   // If not, add the query string
    //   currentURL += `?${newQueryString}`;
    // }
    // // Reload the page with the updated URL
    // window.location.href = currentURL;
    window.location.reload();
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_APP_GRAPH_API_URL,
});

const cache = new InMemoryCache({
  typePolicies: {
    // Query: {
    //   fields: {
    //     board: {
    //       read(_, { args, toReference }) {
    //         const { boardUuid } = args;
    //         if (!boardUuid) {
    //           return null;
    //         }
    //         return toReference({
    //           __typename: 'Board',
    //           uuid: boardUuid,
    //         });
    //       },
    //     },
    //   },
    // },
    Resume: {
      keyFields: ['uuid'],
    },
    User: {
      keyFields: ['uuid'],
    },
    File: {
      keyFields: ['uuid'],
    },
    Board: {
      keyFields: ['uuid'],
    },
    Job: {
      keyFields: ['uuid'],
    },
    JobPost: {
      keyFields: ['uuid'],
    },
    Event: {
      keyFields: ['uuid'],
    },
    Contact: {
      keyFields: ['uuid'],
    },
    Task: {
      keyFields: ['uuid'],
    },
    Note: {
      keyFields: ['uuid'],
    },
    AiCoverLetter: {
      keyFields: ['uuid'],
    },
    AiEmail: {
      keyFields: ['uuid'],
    },
  },
});

export const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-only',
    },
  },
});
