import { Route, Outlet, Routes as ReactRouterDomRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { Urls } from './urls';
import { ProtectedRoute } from './ProtectedRoute';

import { NavBar } from '../components/molecules/NavBar';
import { Footer } from '../components/organisms/Footer';
import { GlobalLoader } from '../components/atoms/Loader/Loader';
import { Layout, LayoutFullWidth } from '../components/atoms/Layout/Layout';

const TermsOfUse = lazy(() => import('./Static/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('./Static/PrivacyPolicy'));
const Features = lazy(() => import('./Static/Features'));
const Faqs = lazy(() => import('./Static/Faqs'));
const Security = lazy(() => import('./Static/Security'));
const About = lazy(() => import('./Static/About'));
const Pricing = lazy(() => import('./Static/Pricing'));
const JobView = lazy(() => import('./Job/View'));
const JobPostView = lazy(() => import('./JobPosts/View'));
const Home = lazy(() => import('./Static/Home'));
const JobTracker = lazy(() => import('./Tools/JobTracker'));
const CoverLetterGenerator = lazy(() => import('./Tools/CoverLetterGenerator'));
const ResumeGenerator = lazy(() => import('./Tools/ResumeGenerator'));
const NotFound404 = lazy(() => import('./NotFound404'));
const Login = lazy(() => import('./Auth/Login'));
const Settings = lazy(() => import('./Settings'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const Boards = lazy(() => import('./Board/List'));
const BoardView = lazy(() => import('./Board/View'));
const BoardUpdate = lazy(() => import('./Board/Update'));
const JobUpdate = lazy(() => import('./Job/Update'));
const ResumeUpdate = lazy(() => import('./Resume/Update'));
const JobOfferUpdate = lazy(() => import('./JobPosts/Update'));
const Checkout = lazy(() => import('./Checkout/Checkout'));
const CheckoutConfirm = lazy(() => import('./Checkout/CheckoutConfirm'));
const JobPosts = lazy(() => import('./JobPosts'));
const AdminJobPosts = lazy(() => import('./Admin/JobPost'));
const AdminUsers = lazy(() => import('./Admin/User'));
const PublicBoardView = lazy(() => import('./Board/PublicView'));
const PublicJobView = lazy(() => import('./Job/PublicView'));
const PublicBoardResumeView = lazy(() => import('./Resume/PublicView'));

export const Routes = () => {
  return (
    <ReactRouterDomRoutes>
      <Route path={Urls.Home} element={<Root />}>
        <Route index element={<Home />} />

        <Route path={Urls.Static} element={<RouteStatic />}>
          <Route path={Urls.TermsOfUse} element={<TermsOfUse />} />
          <Route path={Urls.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={Urls.Features} element={<Features />} />
          <Route path={Urls.Faqs} element={<Faqs />} />
          <Route path={Urls.Pricing} element={<Pricing />} />
          <Route path={Urls.About} element={<About />} />
          <Route path={Urls.Security} element={<Security />} />
        </Route>

        <Route path={Urls.Tools} element={<RouteTools />}>
          <Route path={Urls.JobTracker} element={<JobTracker />} />
          <Route path={Urls.CoverLetterGenerator} element={<CoverLetterGenerator />} />
          <Route path={Urls.ResumeGenerator} element={<ResumeGenerator />} />
        </Route>

        <Route path={Urls.Admin} element={<RouteAdmin />}>
          <Route path={Urls.AdminJobPosts} element={<AdminJobPosts />} />
          <Route path={Urls.AdminUsers} element={<AdminUsers />} />
          <Route index element={<NotFound404 />} />
        </Route>

        <Route path={Urls.Public} element={<RouteStatic />}>
          <Route path={Urls.PublicBoardView} element={<PublicBoardView />} />
          <Route path={Urls.PublicJobView} element={<PublicJobView />} />
          <Route path={Urls.PublicJobResumeView} element={<PublicBoardResumeView />} />
          <Route path={Urls.PublicBoardResumeView} element={<PublicBoardResumeView />} />
          <Route index element={<NotFound404 />} />
        </Route>

        <Route path={Urls.Dashboard} element={<RouteDashboard />}>
          <Route index element={<Dashboard />} />
        </Route>

        <Route path={Urls.Boards} element={<RouteBoards />}>
          <Route path={Urls.BoardView} element={<BoardView />} />
          <Route path={Urls.BoardUpdate} element={<BoardUpdate />} />
          <Route index element={<Boards />} />
        </Route>

        <Route path={Urls.ResumeUpdateHome} element={<RouteResumeFlow />}>
          <Route path={Urls.BoardResumeUpdateStepGeneric} element={<ResumeUpdate />} />
          <Route path={Urls.JobResumeUpdateStepGeneric} element={<ResumeUpdate />} />
        </Route>

        <Route path={Urls.JobUpdateHome} element={<RouteJobFlow />}>
          <Route path={Urls.JobUpdateStep1} element={<JobUpdate />} />
          <Route path={Urls.JobUpdateStep2} element={<JobUpdate />} />
          <Route path={Urls.JobUpdateStep3} element={<JobUpdate />} />
          <Route path={Urls.JobUpdateStep4} element={<JobUpdate />} />
          <Route path={Urls.JobUpdateStep5} element={<JobUpdate />} />
        </Route>

        <Route path={Urls.JobOfferUpdateHome} element={<RouteJobFlow />}>
          <Route path={Urls.JobOfferUpdateStep1} element={<JobOfferUpdate />} />
          <Route path={Urls.JobOfferUpdateStep2} element={<JobOfferUpdate />} />
          <Route path={Urls.JobOfferUpdateStep3} element={<JobOfferUpdate />} />
          <Route path={Urls.JobOfferUpdateStep4} element={<JobOfferUpdate />} />
          <Route path={Urls.JobOfferUpdateStep5} element={<JobOfferUpdate />} />
        </Route>

        <Route path={Urls.Jobs} element={<RouteJob />}>
          <Route path={Urls.JobView} element={<JobView />} />
          <Route index element={<Boards />} />
        </Route>

        <Route path={Urls.JobPosts} element={<RouteJob />}>
          <Route path={Urls.JobPostView} element={<JobPostView />} />
          <Route index element={<JobPosts />} />
        </Route>

        <Route path={Urls.Settings} element={<RouteSettings />}>
          <Route index element={<Settings />} />
        </Route>

        <Route path={Urls.Login} element={<Login />} />

        <Route path={Urls.Checkout} element={<RouteCheckout />}>
          <Route path={Urls.CheckoutConfirm} element={<CheckoutConfirm />} />
          <Route index element={<Checkout />} />
        </Route>

        <Route path="*" element={<NotFound404 />} />
      </Route>
    </ReactRouterDomRoutes>
  );
};

function Root() {
  return (
    <>
      {/* <PushProvider> */}
      <NavBar />
      {/* </PushProvider> */}
      <Suspense fallback={<GlobalLoader />}>
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
}

function RouteStatic() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

function RouteTools() {
  return (
    <LayoutFullWidth>
      <Outlet />
    </LayoutFullWidth>
  );
}

function RouteCheckout() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteSettings() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteDashboard() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteBoards() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteAdmin() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteJob() {
  return (
    <Layout>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </Layout>
  );
}

function RouteJobFlow() {
  return (
    <LayoutFullWidth>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </LayoutFullWidth>
  );
}

function RouteResumeFlow() {
  return (
    <LayoutFullWidth>
      <ProtectedRoute>
        <Outlet />
      </ProtectedRoute>
    </LayoutFullWidth>
  );
}
