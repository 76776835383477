import { createGlobalStyle } from 'styled-components';

import { BaseFontStyles, HeadingStyle1 } from './fonts';
import { media } from './media';
import { Colors } from './colors';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    ${BaseFontStyles}
  }
  #root {
    height: 100%;
  }
  button {
    font: inherit;
    appearance: none;
    background-color: transparent;
    padding: 0;
    outline: 0;
    border: 0;
    cursor: pointer;
  }
  h1,
  h2,
  h3,
  p {
    margin-top: 0;
  }
  h1 {
    ${HeadingStyle1}
  }
  // https://typescale.com/
  :root {
    --font-size-xs: 0.694rem;
    --font-size-sm: 0.833rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.2rem;
    --font-size-xl: 1.50rem;
    --font-size-2xl: 1.728rem;
    --font-size-3xl: 2.074rem;
    --font-size-4xl: 2.488rem;

    ${media.from_tablet`
      --font-size-xs: 0.64rem;
      --font-size-sm: 0.8rem;
      --font-size-md: 1rem;
      --font-size-lg: 1.25rem;
      --font-size-xl: 1.563rem;
      --font-size-2xl: 1.953rem;
      --font-size-3xl: 2.441rem;
      --font-size-4xl: 3.052rem;
    `};

    ${media.from_laptop`
      --font-size-xs: 0.563rem;
      --font-size-sm: 0.75rem;
      --font-size-md: 1rem;
      --font-size-lg: 1.333rem;
      --font-size-xl: 1.777rem;
      --font-size-2xl: 2.369rem;
      --font-size-3xl: 3.157rem;
      --font-size-4xl: 4.209rem;
    `};

    --min-tap-target-height: 24px;
    --right-left-global-padding: 12px;
    --spacer-medium: 32px;
    --spacer-large: 64px;
    @media (min-width: 1024px) {
      --min-tap-target-height: 32px;
      --right-left-global-padding: 16px;
      --spacer-medium: 64px;
      --spacer-large: 128px;
    }

    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #A7BDA7;
    --toastify-color-success: #A7BDA7;
    --toastify-color-warning: #D4C4D4;
    --toastify-color-error: #BEA7BE;

    --toastify-color-progress-dark: #121212;
    --toastify-color-progress-info: #A7BDA7;
    --toastify-color-progress-success: #A7BDA7;
    --toastify-color-progress-warning: #A7BDA7;
    --toastify-color-progress-error: #A7BDA7;
    --toastify-z-index: 9999;

    --aspect-ratio: 0.7071;
    --shadow-elevation-one: '0 1px 3px rgba(39, 39, 42, .03), 0 4px 8px rgba(39, 39, 42, .04)'
  }

  // To be improved
  .MuiTooltip-tooltip {
    background-color: ${Colors.Contrast};

    p {
      padding: 8px 12px;
      font-size: 1.2rem;
      font-weight: 400;
      background-color: ${Colors.White};
      color: ${Colors.Grey};
    }
  }

  .MuiTooltip-arrow {
    color: ${Colors.Contrast};
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
`;
